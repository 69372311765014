// https://www.radix-ui.com/docs/primitives/components/dropdown-menu
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'

export function Root(props: RadixDropdownMenu.DropdownMenuProps) {
  return <RadixDropdownMenu.Root {...props} />
}

export function Trigger(props: RadixDropdownMenu.DropdownMenuTriggerProps) {
  return <RadixDropdownMenu.Trigger {...props} />
}

export function Portal(props: RadixDropdownMenu.DropdownMenuPortalProps) {
  return <RadixDropdownMenu.Portal {...props} />
}

export function Content(props: RadixDropdownMenu.DropdownMenuContentProps) {
  return <RadixDropdownMenu.Content {...props} />
}

export function Item(props: RadixDropdownMenu.DropdownMenuItemProps) {
  return <RadixDropdownMenu.Item {...props} />
}
