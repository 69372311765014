import { Button } from 'primitives/button'
import { CrossIcon } from 'primitives/icons'
import { useTranslations } from 'next-intl'

import { SlideInMenu } from '@ask-cf/components/slide-in-menu'
import { useUser } from '@ask-cf/hooks/use-user-data'
import { LoggedInProfile } from '@ask-cf/components/user-profile-panel'
import { LogOutButton } from '@ask-cf/components/log-out-button'

export function UserMenu({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const user = useUser()
  const t = useTranslations('ask_cf.components.mobile_menu')
  return (
    <SlideInMenu
      isOpen={isOpen}
      onClose={onClose}
      className="tablet:hidden z-50 flex flex-col"
      position="right"
      backdropClassName="xl:hidden"
    >
      <Button
        variant={'invisible'}
        onClick={onClose}
        className="absolute left-4 top-4 z-20 h-auto rounded-full bg-white !p-2.5"
        aria-label={t('close_menu_aria_label')}
      >
        <CrossIcon className="text-text-tertiary" />
      </Button>
      <div className="grow">
        <LoggedInProfile user={user} onItemClick={onClose} />
      </div>
      <LogOutButton className="pb-4 pl-6" />
    </SlideInMenu>
  )
}
