import { useHits, useConnector, useInfiniteHits } from 'react-instantsearch'
import connectAutocomplete, {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'

export enum AlgoliaLanguage {
  EN = 'LANGUAGE_EN',
  FR = 'LANGUAGE_FR',
  DE = 'LANGUAGE_DE',
  ES = 'LANGUAGE_ES',
  IT = 'LANGUAGE_IT',
  PT = 'LANGUAGE_PT_BR',
  NL = 'LANGUAGE_NL',
  PL = 'LANGUAGE_PL',
}

export type AlgoliaTranslatedString = Record<AlgoliaLanguage, string>

export interface AlgoliaContentHit extends Record<string, unknown> {
  ai_answer?: string
  best_answer?: string
  context: string
  numberofanswers: number
  objectID: string
  question: string
  question_id: string
  question_slug: string
  topic: string
  topic_id: string
  topic_slug: string
  _highlightResult: {
    question: HighlightResult
    questionDescription: HighlightResult
    topic: HighlightResult
    aiAnswer: HighlightResult
  }
}

interface HighlightResult {
  value: string
  matchLevel: string
  fullyHighlighted?: boolean
  matchedWords: string[]
}

export type AlgoliaSearchedProductsLocalStorage = {
  items: Record<string, Record<string, string>>
  expiresAt: Date
}

export function useAlgoliaHits(...args: Parameters<typeof useHits<AlgoliaContentHit>>) {
  return useHits<AlgoliaContentHit>(...args)
}

export function useAlgoliaInfiniteHits(
  ...args: Parameters<typeof useInfiniteHits<AlgoliaContentHit>>
) {
  return useInfiniteHits<AlgoliaContentHit>(...args)
}

export type UseAutocompleteProps = AutocompleteConnectorParams

export function useAutocomplete(props?: UseAutocompleteProps) {
  return useConnector<AutocompleteConnectorParams, AutocompleteWidgetDescription>(
    connectAutocomplete,
    props,
  )
}
