import { Language } from 'codegen/types'

export const languageItems: { id: Language; label: string }[] = [
  {
    id: Language.En,
    label: 'English',
  },
  {
    id: Language.Es,
    label: 'Español',
  },
  {
    id: Language.De,
    label: 'Deutsch',
  },
  {
    id: Language.Pt,
    label: 'Português (BR)',
  },
  {
    id: Language.Fr,
    label: 'Français',
  },
  {
    id: Language.It,
    label: 'Italiano',
  },
  {
    id: Language.Nl,
    label: 'Nederlands',
  },
  {
    id: Language.Pl,
    label: 'Polski',
  },
]

export const languages: Language[] = languageItems.map(item => item.id)
export const defaultLanguage = 'en'
