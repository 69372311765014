'use client'

import * as React from 'react'
import { useTranslations } from 'next-intl'
import { tw } from 'utils/classnames'

import { LogOutIcon } from '@ask-cf/assets/icons'
import { StyledLink } from '@ask-cf/components/styled-link'
import { ROUTES } from '@ask-cf/constants/app-routes'

export function LogOutButton({
  className,
  spanClassName,
}: {
  className?: string
  spanClassName?: string
}) {
  const t = useTranslations('ask_cf.components.log_out_button')
  const [currentUrl, setCurrentUrl] = React.useState('')

  React.useEffect(() => {
    if (window) {
      setCurrentUrl(window.location.href)
    }
  }, [])

  return (
    <StyledLink
      href={ROUTES.LOG_OUT(currentUrl)}
      className={tw('hover:text-blue-700', className)}
      aria-label={t('label')}
    >
      <LogOutIcon />
      <span className={tw('text-md ml-1 whitespace-nowrap leading-6', spanClassName)}>
        {t('label')}
      </span>
    </StyledLink>
  )
}
