'use client'

import * as React from 'react'

import { Post } from '@ask-cf/components/feed/models'

export interface AnswerDialogContextModel {
  isOpen: boolean
  post: Post | null
  openDialog: (post: Post) => void
  closeDialog: () => void
}

const AnswerDialogContext = React.createContext<AnswerDialogContextModel>(null!)

export const AnswerDialogProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [post, setPost] = React.useState<Post | null>(null)

  const openDialog = (post: Post) => {
    setIsOpen(true)
    setPost(post)
  }

  const closeDialog = () => {
    setIsOpen(false)
    setPost(null)
  }

  return (
    <AnswerDialogContext.Provider value={{ isOpen, post, closeDialog, openDialog }}>
      {children}
    </AnswerDialogContext.Provider>
  )
}

export const useAnswerDialog = () => {
  const context = React.useContext(AnswerDialogContext)

  if (context === undefined) {
    throw new Error('useAnswerDialog must be used within a AnswerDialogProvider')
  }

  return context
}
