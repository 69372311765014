'use client'

import Script from 'next/script'
import * as React from 'react'

import { useIsLoggedInQuery } from '../hooks/use-is-logged-in-query'
import { useUserId } from '../hooks/use-user-id'
import {
  initScript as googleSiteTagInitScript,
  scriptUrl as googleSiteTagScriptUrl,
  globalSiteInit,
} from '../google-site-tag'

export const GlobalSiteTag = () => {
  const isLoggedInQuery = useIsLoggedInQuery()
  const userId = useUserId()

  React.useEffect(() => {
    if (isLoggedInQuery.isFetched) {
      if (typeof gtag === 'function') {
        globalSiteInit(isLoggedInQuery.data ? userId.data || null : null)
      }
    }
  }, [isLoggedInQuery.isFetched, isLoggedInQuery.data, userId.data])

  return (
    <>
      <Script id="global-site-tag-script-init" strategy="afterInteractive">
        {googleSiteTagInitScript}
      </Script>
      <Script
        id="global-site-tag-script"
        src={googleSiteTagScriptUrl}
        strategy="afterInteractive"
      />
    </>
  )
}
