import { EditorConfig } from 'lexical'
import { LinkNode, SerializedLinkNode, SerializedAutoLinkNode } from '@lexical/link'

const CF_MAIN_DOMAIN = 'creativefabrica.com'

export class NoFollowLinkNode extends LinkNode {
  constructor(url: string, key?: string) {
    super(url, undefined, key)
  }

  static getType() {
    return 'custom-link'
  }

  static clone(node: LinkNode) {
    return new NoFollowLinkNode(node.__url, node.__key)
  }

  static importJSON(serializedNode: SerializedLinkNode | SerializedAutoLinkNode) {
    return super.importJSON(serializedNode)
  }

  getDomainFromUrl(url: string): string {
    try {
      const parsedUrl = new URL(url)
      return parsedUrl.hostname
    } catch (error) {
      return ''
    }
  }

  createDOM(config: EditorConfig) {
    const element = super.createDOM(config)
    const domain = this.getDomainFromUrl(this.getURL())
    if (element instanceof HTMLAnchorElement) {
      if (!domain.toLowerCase().includes(CF_MAIN_DOMAIN)) {
        element.rel = 'nofollow'
      }
      element.target = '_blank'
    }
    return element
  }
}
