import * as React from 'react'
import * as Dialog from 'primitives/dialog'
import { useTranslations } from 'next-intl'

import { useSocialShareDialog } from '@ask-cf/contexts/social-share-dialog-context'
import { SHARE_OPTIONS, ShareOption } from '../models/share-options'
import { CopyIcon } from '@ask-cf/assets/icons'

export function SocialShareDialogBody({ shareUrl }: { shareUrl: string }) {
  const t = useTranslations('ask_cf.components.social_share_dialog')
  const { closeDialog } = useSocialShareDialog()

  return (
    <section className="rounded-2 relative w-[742px] max-w-[90%] bg-white p-6">
      <header className="mb-5">
        <h2 className="text-text-secondary text-lg font-medium">{t('title')}</h2>
      </header>
      <main>
        <div className="flex flex-wrap gap-4">
          {SHARE_OPTIONS.map(shareOption => (
            <ShareButton key={shareOption.label} shareOption={shareOption} />
          ))}
        </div>
        <ReadonlyCopyTextbox text={shareUrl} />
      </main>
      <Dialog.CloseX
        className="hover:bg-text-hover right-0 top-0 z-50 h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white"
        onClick={closeDialog}
      />
    </section>
  )
}

function ShareButton({ shareOption }: { shareOption: ShareOption }) {
  const { shareUrl } = useSocialShareDialog()
  const encodedShareUrl = encodeURIComponent(shareUrl!)
  const href = shareOption.getHref(encodedShareUrl)

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="text-text-secondary rounded-2 hover:text-text-hover flex items-center gap-3 border border-gray-400 px-4 py-2"
    >
      {shareOption.icon}
      <span className="text-md font-medium">{shareOption.label}</span>
    </a>
  )
}

function ReadonlyCopyTextbox({ text }: { text: string }) {
  const t = useTranslations('ask_cf.components.social_share_dialog')
  const [isCopied, setIsCopied] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  function copyToClipboard() {
    if (inputRef.current) {
      inputRef.current.select()
      navigator.clipboard.writeText(text)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 3000)
    }
  }

  return (
    <div className="relative mt-5 w-full">
      <input
        ref={inputRef}
        type="text"
        value={text}
        disabled
        readOnly
        className="bg-background-primary rounded-2 text-text-tertiary text-md h-[56px] w-full border border-gray-400 p-4 font-medium leading-6"
      />
      <button
        onClick={copyToClipboard}
        className="rounded-2 text-md hover:text-text-hover absolute bottom-0 right-0 top-0 flex items-center gap-3 border border-gray-400 bg-white p-4 text-blue-400"
        aria-label={t('copy_link')}
      >
        <CopyIcon />
        <span className="font-medium">{t(isCopied ? 'copied' : 'copy_link')}</span>
      </button>
    </div>
  )
}
