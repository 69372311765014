import * as RadixCheckbox from 'primitives/checkbox'
import { CheckIcon } from 'primitives/icons'
import { tw } from 'utils/classnames'

type CheckboxProps = React.ComponentProps<typeof RadixCheckbox.Root> & {
  label?: React.ReactNode
}

export function Checkbox({ label, ...props }: CheckboxProps) {
  return (
    <label className="group flex cursor-pointer items-center gap-2">
      <RadixCheckbox.Root
        {...props}
        className={tw(
          'h-4 w-4 rounded',
          'border border-[#D0D5DD] hover:border-gray-400',
          "data-[state='checked']:bg-text-hover data-[state='checked']:border-none",
          props.className,
        )}
      >
        <Indicator />
      </RadixCheckbox.Root>
      {label ? (
        <span className="text-sm font-medium leading-[20px] text-[#344054] group-hover:text-gray-700">
          {label}
        </span>
      ) : null}
    </label>
  )
}

function Indicator() {
  return (
    <RadixCheckbox.Indicator className="data-[state='checked']:bg-text-hover h-full w-full rounded bg-white">
      <CheckIcon className="text-service-white h-auto w-full" />
    </RadixCheckbox.Indicator>
  )
}
