import { IconProps } from '.'

export function FacebookShareIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#1877F2" />
      <path
        d="M22.2281 20.625L22.9375 16H18.5V13C18.5 11.7344 19.1188 10.5 21.1063 10.5H23.125V6.5625C23.125 6.5625 21.2938 6.25 19.5438 6.25C15.8875 6.25 13.5 8.46563 13.5 12.475V16H9.4375V20.625H13.5V31.8063C14.3156 31.9344 15.15 32 16 32C16.85 32 17.6844 31.9344 18.5 31.8063V20.625H22.2281Z"
        fill="white"
      />
    </svg>
  )
}

export function PinterestShareIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3732_269728)">
        <path
          d="M15.9934 0C7.14768 0 0 7.16083 0 15.9934C0 22.7725 4.21226 28.5644 10.1621 30.8943C10.0173 29.6306 9.89882 27.6824 10.2147 26.3003C10.5043 25.0498 12.0839 18.3497 12.0839 18.3497C12.0839 18.3497 11.61 17.3887 11.61 15.9803C11.61 13.7556 12.9 12.0971 14.506 12.0971C15.8749 12.0971 16.5331 13.1238 16.5331 14.348C16.5331 15.717 15.6643 17.7705 15.2036 19.6791C14.8219 21.2719 16.0066 22.5751 17.573 22.5751C20.4163 22.5751 22.6014 19.5738 22.6014 15.2563C22.6014 11.4258 19.8503 8.7536 15.9144 8.7536C11.3599 8.7536 8.68778 12.1629 8.68778 15.6907C8.68778 17.0596 9.2143 18.5339 9.87248 19.3369C10.0041 19.4948 10.0173 19.6396 9.97779 19.7976C9.85933 20.2978 9.58288 21.3904 9.53024 21.6141C9.46442 21.9037 9.29328 21.9696 8.99053 21.8248C7.01603 20.877 5.77869 17.9548 5.77869 15.6117C5.77869 10.5701 9.43808 5.93664 16.3488 5.93664C21.8906 5.93664 26.2082 9.88563 26.2082 15.1773C26.2082 20.6927 22.733 25.1288 17.9153 25.1288C16.2962 25.1288 14.7692 24.2863 14.2559 23.2859C14.2559 23.2859 13.4529 26.3398 13.2555 27.0901C12.9 28.4854 11.926 30.2229 11.2678 31.2892C12.7684 31.7499 14.348 32 16.0066 32C24.8392 32 32 24.8392 32 16.0066C31.9868 7.16083 24.826 0 15.9934 0Z"
          fill="#BD081C"
        />
      </g>
      <defs>
        <clipPath id="clip0_3732_269728">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function EmailShareIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3732_269749"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3732_269749)">
        <path
          d="M25.6448 30.5765L24.0892 29.0209L26.4115 26.6654H19.7781V24.4432H26.4115L24.0559 22.0876L25.6448 20.532L30.667 25.5543L25.6448 30.5765ZM4.88919 26.6654C4.27439 26.6654 3.75033 26.4487 3.31699 26.0154C2.88366 25.582 2.66699 25.058 2.66699 24.4432V7.55423C2.66699 6.93943 2.88366 6.41536 3.31699 5.98203C3.75033 5.5487 4.27439 5.33203 4.88919 5.33203H27.1115C27.7263 5.33203 28.2503 5.5487 28.6837 5.98203C29.117 6.41536 29.3337 6.93943 29.3337 7.55423V18.4431C28.7633 18.1468 28.1688 17.9246 27.5503 17.7765C26.9318 17.6283 26.2966 17.5543 25.6448 17.5543C23.3929 17.5543 21.4818 18.3395 19.9115 19.9098C18.3411 21.4802 17.5559 23.3913 17.5559 25.6432V26.6654H4.88919ZM16.0003 16.8209L27.1115 9.77646V7.55423L16.0003 14.5098L4.88919 7.55423V9.77646L16.0003 16.8209Z"
          fill="#113CB9"
        />
      </g>
    </svg>
  )
}
