'use client'
import * as React from 'react'
import { useRouter } from 'next/navigation'
import { useLocale } from 'next-intl'

import { ROUTES } from '@ask-cf/constants/app-routes'

interface NotifyLoginDialogContextType {
  isOpen: boolean
  message: string
  openLoginDialog: (message: string) => void
  closeLoginDialog: () => void
  setRedirecting: (redirect: boolean) => void
  currentView: 'notify' | 'login'
  setCurrentView: (view: 'notify' | 'login') => void
}

const NotifyLoginDialogContext = React.createContext<NotifyLoginDialogContextType>(null!)

const NotifyLoginDialogProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<string>('')
  const [isRedirecting, setIsRedirecting] = React.useState<boolean>(false)
  const [currentView, setCurrentView] = React.useState<'notify' | 'login'>('notify')
  const router = useRouter()
  const locale = useLocale()

  const openLoginDialog = (msg = '') => {
    setCurrentView('notify')
    setMessage(msg)
    setIsOpen(true)
  }

  const closeLoginDialog = () => {
    setIsOpen(false)
    setMessage('')
    if (isRedirecting && currentView === 'notify') {
      router.push(ROUTES.HOME(locale))
    }
  }

  const setRedirecting = (redirect: boolean) => {
    setIsRedirecting(redirect)
  }

  return (
    <NotifyLoginDialogContext.Provider
      value={{
        isOpen,
        message,
        openLoginDialog,
        closeLoginDialog,
        setRedirecting,
        currentView,
        setCurrentView,
      }}
    >
      {children}
    </NotifyLoginDialogContext.Provider>
  )
}

export { NotifyLoginDialogProvider, NotifyLoginDialogContext }

export function useNotifyLoginDialog() {
  const context = React.useContext(NotifyLoginDialogContext)

  if (context === undefined) {
    throw new Error('useNotifyLoginDialog must be used within a LoginDialogProvider')
  }
  return context
}
