'use client'
import { CFAskLogo } from 'primitives/icons'
import { usePathname } from 'next/navigation'
import { tw } from 'utils/classnames'
import { useLocale, useTranslations } from 'next-intl'
import * as React from 'react'
import { ButtonIcon } from 'primitives/button'

import { SearchBanner } from '../search-banner'
import { MenuIcon } from '@ask-cf/assets/icons'
import { StyledLink } from '../styled-link'
import { UserHeaderSection } from './components/user-header-section'
import { Links } from './components/navigation-links'
import { UserMenu } from './components/user-menu'
import { MobileMenu } from './components/mobile-menu'
import { ROUTES } from '@ask-cf/constants/app-routes'

export function Navbar() {
  const [showMenu, setShowMenu] = React.useState(false)
  const [showUserProfileMenu, setShowUserProfileMenu] = React.useState(false)
  const locale = useLocale()
  const pathname = usePathname()

  React.useEffect(() => {
    setShowMenu(false)
  }, [pathname])

  const t = useTranslations('ask_cf.components.navbar')

  return (
    <div className="fixed top-0 z-20 w-full">
      <nav
        className={tw(
          'flex h-[56px] w-full flex-row items-center bg-white px-4 xl:h-[84px] xl:px-16 xl:py-5',
        )}
      >
        <div
          className={tw(
            'xl:mx-auto xl:max-w-[1311px]',
            'flex w-full flex-row items-center gap-2 xl:gap-8',
            'pr-[--removed-body-scroll-bar-size]',
          )}
        >
          <ButtonIcon
            className="block xl:hidden"
            onClick={() => setShowMenu(current => !current)}
            aria-label={t('toggle_menu_aria_label')}
          >
            <MenuIcon className="text-text-tertiary" />
          </ButtonIcon>
          <StyledLink href={ROUTES.HOME(locale)} aria-label={t('home_link_aria_label')}>
            <CFAskLogo className="h-8 xl:h-11" />
          </StyledLink>
          <MobileMenu isOpen={showMenu} onClose={() => setShowMenu(false)} />
          <ul id="nav-links" className="hidden gap-6 xl:flex">
            <Links />
          </ul>
          <div className="ml-auto">
            <UserHeaderSection onUserMenuOpen={() => setShowUserProfileMenu(current => !current)} />
          </div>
          <UserMenu isOpen={showUserProfileMenu} onClose={() => setShowUserProfileMenu(false)} />
        </div>
      </nav>
      <SearchBanner />
    </div>
  )
}
