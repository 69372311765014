'use client'
import { useIsLoggedInQuery } from 'auth/hooks'
import { Language } from 'codegen/types'
import { useLocale } from 'next-intl'
import { ChevronDownIcon } from 'primitives/icons'
import * as Select from 'primitives/select'
import { tw } from 'utils/classnames'
import { useRouter } from 'next/navigation'

import { languageItems, languages } from '@ask-cf/constants/languages'
import { TranslateIcon } from '@ask-cf/assets/icons'
import { useUpdateLanguageMutation } from '@ask-cf/hooks/use-update-language-mutation'

export function LanguageSelect({
  className,
  align,
}: {
  className?: string
  align?: 'start' | 'center' | 'end'
}) {
  const locale = useLocale()
  // eslint-disable-next-line local/codegen-query-patterns
  const isUserLoggedIn = useIsLoggedInQuery()
  const currentLanguage = locale || 'en'
  const router = useRouter()

  const { updateLanguage } = useUpdateLanguageMutation()

  function handleLanguageChange(value: string) {
    const redirectToNewLocale = () => {
      if (!document) {
        return
      }
      const location = document.location.pathname
      const languageSegment = location.split('/')[1]
      const hasLocationSegment = languages.includes(languageSegment as Language)

      const newLocation = hasLocationSegment
        ? location.replace(`/${languageSegment}/`, `/${value}/`)
        : `/${value}${location}`

      router.push(newLocation)
    }
    if (isUserLoggedIn.data) {
      updateLanguage(value as Language)
    } else {
      redirectToNewLocale()
    }
  }

  return (
    <Select.Root defaultValue={currentLanguage} onValueChange={handleLanguageChange}>
      <Select.Trigger
        className={tw(
          'bg-background-primary text-text-tertiary flex flex-row items-center justify-center gap-1 rounded-[4px] px-2 pt-1',
          className,
        )}
        aria-label="language"
      >
        <TranslateIcon />
        <Select.Value />
        <Select.Icon className="SelectIcon">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          position="popper"
          align={align || 'start'}
          className="shadow-dropdown z-50 rounded-[4px] bg-white p-2"
        >
          <Select.Viewport className="SelectViewport">
            <Select.Group>
              {languageItems.map(language => (
                <SelectItem key={language.id} {...language} />
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}

function SelectItem({ label, id }: { label: string; id: Language }) {
  return (
    <Select.Item
      className="text-text-primary cursor-pointer p-1 opacity-60 outline-none hover:opacity-100"
      value={id}
    >
      <Select.ItemText>{label}</Select.ItemText>
    </Select.Item>
  )
}
