'use client'

import * as React from 'react'

export interface SocialShareContextModel {
  isOpen: boolean
  shareUrl: string | null
  openDialog: (shareUrl: string) => void
  closeDialog: () => void
}

const SocialShareDialogContext = React.createContext<SocialShareContextModel>(null!)

export const SocialShareDialogProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [shareUrl, setShareUrl] = React.useState<string | null>(null)

  const openDialog = (shareUrl: string) => {
    setIsOpen(true)
    setShareUrl(shareUrl)
  }

  const closeDialog = () => {
    setShareUrl(null)
    setIsOpen(false)
  }

  return (
    <SocialShareDialogContext.Provider value={{ isOpen, openDialog, shareUrl, closeDialog }}>
      {children}
    </SocialShareDialogContext.Provider>
  )
}

export const useSocialShareDialog = () => {
  const context = React.useContext(SocialShareDialogContext)

  if (context === undefined) {
    throw new Error('useSocialShareDialog must be used within a SocialShareDialogProvider')
  }

  return context
}
