'use client'
import * as React from 'react'
import * as Dialog from 'primitives/dialog'

import { AnswerDialogBody } from './components/answer-dialog-body'
import { useAnswerDialog } from '@ask-cf/contexts/answer-dialog-context'

export function AnswerDialog() {
  const { isOpen, closeDialog, post } = useAnswerDialog()

  return (
    <Dialog.Root open={isOpen} onOpenChange={open => !open && closeDialog()} defaultOpen={false}>
      <React.Suspense fallback={null}>
        <Dialog.Portal>
          <Dialog.Body
            className="font-poppins z-50 xl:items-start xl:overflow-y-auto xl:py-10"
            overlayZIndex={49}
            dismissable={false}
          >
            {post ? <AnswerDialogBody post={post} /> : null}
          </Dialog.Body>
        </Dialog.Portal>
      </React.Suspense>
    </Dialog.Root>
  )
}
