import { useUserSettingsQuery, type UserSettingsQuery } from 'codegen/generated/ask-cf'
import { useQueryClient, useIsMutating } from '@tanstack/react-query'
import merge from 'lodash.merge'
import * as Sentry from '@sentry/react'
import type { Language } from 'codegen/types'
import { useUpdateUserLanguageMutation } from 'codegen/generated/user-preferences'

import { languages } from '@ask-cf/constants/languages'

export function useUpdateLanguageMutation() {
  const queryClient = useQueryClient()
  const askCfUpdateLanguage = useUpdateUserLanguageMutation({
    onMutate: async variables => {
      await queryClient.cancelQueries({
        queryKey: useUserSettingsQuery.getKey(),
      })

      const prev = queryClient.getQueryData(useUserSettingsQuery.getKey())
      queryClient.setQueryData<UserSettingsQuery>(
        useUserSettingsQuery.getKey(),
        merge(prev, { me: { user: { language: variables.language } } }),
      )

      return { prev }
    },
    onError: (err, _newTodo, context) => {
      Sentry.captureException(err)
      queryClient.setQueryData(useUserSettingsQuery.getKey(), context?.prev)
    },
    onSettled: (_data, _error, variables) => {
      const location = document.location.pathname
      const languageSegment = location.split('/')[1]
      const hasLocationSegment = languages.includes(languageSegment as Language)

      const newLanguage = variables.language

      const language = newLanguage === 'en' ? '' : `/${newLanguage}`

      const newLocation = hasLocationSegment
        ? location.replace(`/${languageSegment}/`, `${language}/`)
        : `${language}${location}`

      document.cookie = `lang=${newLanguage}; domain=.creativefabrica.com; path=/`
      if (location !== newLocation) {
        document.location.pathname = newLocation
      }
    },
  })

  function updateLanguage(language: Language) {
    return askCfUpdateLanguage.mutate({
      language,
    })
  }

  return { updateLanguage }
}

export function useIsUpdateLanguageLoading() {
  return useIsMutating({
    mutationKey: useUpdateUserLanguageMutation.getKey(),
  })
}
