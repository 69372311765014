import * as Dialog from 'primitives/dialog'
import * as React from 'react'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { ConfirmationBannerMobile } from './confirmation-banner-mobile'
import StandardConfirmationImage from '../assets/standard-confirmation.webp'
import { SendIcon } from '@ask-cf/assets/icons'
import { StyledButton } from '@ask-cf/components/styled-button'
import { Checkbox } from '@ask-cf/components/checkbox'
import { UserAvatar } from '@ask-cf/components/avatar'
import { ticketGenieUser } from '@ask-cf/components/feed/models'

export function QuestionDialogStandardConfirmation({
  onNext,
  skipConfirmation,
  toggleSkipConfirmation,
}: {
  onNext: () => void
  skipConfirmation: boolean
  toggleSkipConfirmation: () => void
}) {
  const { closeClearModalData } = useQuestionDialog()
  const t = useTranslations('ask_cf.components.question_dialog.confirmations.standard')

  const onClose = () => {
    closeClearModalData()
    window.location.reload()
  }

  return (
    <div className="xl:rounded-2 relative flex h-full w-full flex-col bg-blue-400 pt-4 xl:h-[602px] xl:max-w-[440px] xl:flex-row xl:pt-0">
      <div className={'shrink-0 xl:hidden'}>
        <ConfirmationBannerMobile onClose={onClose} title={t('title')} />
      </div>
      <div className="rounded-t-4 xl:rounded-2 z-10 flex grow flex-col items-center overflow-y-auto bg-white px-6 py-5 xl:pt-10">
        <div className="text-blue-1000 mb-1 hidden text-[28px] font-medium leading-[39.2px] xl:block">
          {t('title')}
        </div>
        <div className="text-sm leading-5 text-gray-600">{t('subtitle')}</div>
        <Image
          width={309}
          height={238}
          src={StandardConfirmationImage}
          alt="Crafter"
          className="xl:hidden"
        />
        <Image
          width={411}
          height={316}
          src={StandardConfirmationImage}
          alt="Crafter"
          className="-mb-4 -mt-6 hidden xl:block"
        />
        <ConfirmationAnimation />
        <div className="mt-auto flex flex-col items-center">
          <Checkbox
            className="font-medium"
            label={t('checkbox_label')}
            checked={skipConfirmation}
            onClick={toggleSkipConfirmation}
          />
          <StyledButton
            variant="blue"
            className="mt-[22px] py-2 text-sm font-semibold leading-[24px] xl:h-[56px] xl:w-[328px]"
            onClick={onNext}
          >
            {t('button_label')}
          </StyledButton>
        </div>
        <Dialog.CloseX
          className="hover:bg-text-hover right-0 top-0 z-50 hidden h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white xl:flex"
          onClick={onClose}
        />
      </div>
    </div>
  )
}

const ConfirmationAnimation = () => {
  return (
    <div className="mt-10 h-[120px] w-full overflow-hidden xl:mt-0 xl:w-[331px]">
      <div className="w-full animate-[question-confirmation-main_4s_ease-in-out_forwards]">
        <AnimationItem
          icon={<SendIcon className="text-green-500" />}
          iconBackgroundClass="bg-green-200"
          i={0}
        />
        <AnimationItem
          icon={<UserAvatar avatarUrl={ticketGenieUser.avatarUrl} size="mediumLarge" />}
          iconBackgroundClass="bg-blue-200"
          i={1}
        />
      </div>
    </div>
  )
}

const AnimationItem = ({
  icon,
  iconBackgroundClass,
  i,
}: {
  icon: React.ReactNode
  iconBackgroundClass: string
  i: number
}) => {
  const t = useTranslations('ask_cf.components.question_dialog.confirmations.standard')
  const animationDelay = `${i * 4}s`
  const animationStyle = {
    animationDelay: i === 0 ? '0s' : animationDelay,
  }
  return (
    <div className="relative flex h-[120px] w-full items-center gap-3">
      <div className="absolute bottom-0 left-6 top-0 z-0 w-[1px] bg-[linear-gradient(0deg,_rgba(228,_231,_236,_0)_-0.57%,_#DEE1EC_49.71%,_rgba(228,_231,_236,_0.0212766)_100%)]" />
      <div
        className={`z-[1] flex h-12 w-12 items-center justify-center rounded-full ${iconBackgroundClass} shrink-0 animate-[question-confirmation-icon-bg_2s_ease-in-out_forwards] overflow-hidden`}
        style={{ ...animationStyle, scale: 0.33 }}
      >
        <div
          className="shrink-0 animate-[question-confirmation-icon_2s_ease-in-out_forwards]"
          style={{
            ...animationStyle,
            top: '150%',
            position: 'absolute',
            transform: 'translateY(-50%)',
          }}
        >
          {icon}
        </div>
      </div>
      <div
        className="animate-[question-confirmation-text_2s_ease-in-out_forwards] text-sm leading-5 text-[#222222] xl:max-w-[240px]"
        style={{
          ...animationStyle,
          top: '200%',
          left: '60px',
          position: 'absolute',
          transform: 'translateY(-50%)',
        }}
      >
        {t(`content${i}`)}
      </div>
    </div>
  )
}
