import { QueryClient } from '@tanstack/react-query'
import { isError, isNetworkError } from 'utils/js'

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError(error, _variables, context) {
        if (isNetworkError(error)) {
          return
        }

        if (isError(error) && !String(error.message)?.startsWith('CF_')) {
          // eslint-disable-next-line no-console
          console.error(error, context)
        }
      },
    },
  },
})
