import * as React from 'react'
import * as Dialog from 'primitives/dialog'
import { useTranslations } from 'next-intl'

import { useNotificationSettingsDialog } from '@ask-cf/contexts/notification-settings-dialog-context'
import { SettingsEnvelopeIcon } from '@ask-cf/assets/icons'
import { Switch } from '@ask-cf/components/switch'

export function NotificationSettingsDialogBody() {
  const t = useTranslations('ask_cf.components.notification_settings_dialog')
  const { closeDialog } = useNotificationSettingsDialog()
  const [notificationSettings, setNotificationSettings] = React.useState({
    newAnswers: true,
    bestAnswers: true,
    comments: true,
  })

  return (
    <section className="relative mx-4 w-[420px] rounded-[12px] bg-white">
      <header className="p-4">
        <h2 className="text-text-secondary font-medium">{t('title')}</h2>
      </header>
      <main>
        <div className="flex items-center bg-[#F7F8FA] px-4 py-3">
          <div className="text-text-secondary flex-grow">{t('subtitle')}</div>
          <SettingsEnvelopeIcon />
        </div>
        <SwitchRow
          value={notificationSettings.newAnswers}
          label={t('notification_types.new_answers')}
          onChange={value => setNotificationSettings(prev => ({ ...prev, newAnswers: value }))}
        />
        <Divider />
        <SwitchRow
          value={notificationSettings.bestAnswers}
          label={t('notification_types.best_answers')}
          onChange={value => setNotificationSettings(prev => ({ ...prev, bestAnswers: value }))}
        />
        <Divider />
        <SwitchRow
          value={notificationSettings.comments}
          label={t('notification_types.comments')}
          onChange={value => setNotificationSettings(prev => ({ ...prev, comments: value }))}
        />
      </main>
      <Dialog.CloseX
        className="hover:bg-text-hover right-0 top-0 z-50 h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white"
        onClick={closeDialog}
      />
    </section>
  )
}

const Divider = () => <div className="h-[1px] w-full bg-gray-400" />

const SwitchRow = ({
  label,
  value,
  onChange,
}: {
  label: string
  value: boolean
  onChange: (value: boolean) => void
}) => {
  return (
    <div className="flex items-center justify-between px-4 py-5">
      <div className="text-text-tertiary">{label}</div>
      <Switch checked={value} onCheckedChange={onChange} />
    </div>
  )
}
