import { IconButton } from 'primitives/buttons/icon-button'
import { tw } from 'utils/classnames'
import { CrossIcon } from 'primitives/icons'
import { useTranslations } from 'next-intl'

export function ConfirmationBannerMobile({
  onClose,
  title,
}: {
  onClose?: () => void
  title: string
}) {
  const t = useTranslations('ask_cf.components.question_dialog')
  return (
    <div className={tw('relative mb-4 overflow-hidden pl-5 xl:hidden')}>
      <div className="flex items-center gap-2">
        <IconButton
          variant="transparent"
          onClick={onClose}
          aria-label={t('close_button_aria_label')}
          asChild
        >
          <CrossIcon width={24} height={24} className="text-white" />
        </IconButton>
        <span className="text-md font-medium leading-6 text-white">{title}</span>
      </div>
    </div>
  )
}
