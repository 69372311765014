'use client'

import * as React from 'react'
import { getCookie, setCookie } from 'utils/dom'
import { customAlphabet } from 'nanoid'

import { initAmplitude, setAmplitudeUser, getAmplitudeUser, getAmplitudeSessionId } from './utils'
import { useIsLoggedInQuery } from '../hooks/use-is-logged-in-query'
import { useUserId } from '../hooks/use-user-id'

export const AMPLITUDE_DEVICE_ID_COOKIE_NAME = 'cfAmpAnonymousId'
export const AMPLITUDE_SESSION_ID_COOKIE_NAME = 'cfAmpSessionId'

let env
try {
  env = process.env.NEXT_PUBLIC_ENV || process.env.VITE_PUBLIC_ENV
} catch {}

const isPreview = !env
const AMPLITUDE_COOKIE_FF = process.env.NEXT_PUBLIC_AMPLITUDE_COOKIE_FF === 'true'
const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)

export const Amplitude = () => {
  const isLoggedInQuery = useIsLoggedInQuery()
  const userId = useUserId()
  const amplitudeCookieDeviceId = getCookie(AMPLITUDE_DEVICE_ID_COOKIE_NAME)
  const amplitudeCookieSessionId = getCookie(AMPLITUDE_SESSION_ID_COOKIE_NAME)

  React.useEffect(() => {
    // TEMP Set Amplitude cookie to test fix, will be removed once we restore Cloudflare worker
    let newCookieValue = undefined
    if (AMPLITUDE_COOKIE_FF && !amplitudeCookieDeviceId) {
      newCookieValue = nanoid()
      setCookie({
        name: AMPLITUDE_DEVICE_ID_COOKIE_NAME,
        value: newCookieValue,
        domain: '.creativefabrica.com',
        expiresIn: 315360000000,
      })
    }

    initAmplitude(amplitudeCookieDeviceId || newCookieValue)

    const amplitudeSessionId = getAmplitudeSessionId()
    if (String(amplitudeSessionId) !== amplitudeCookieSessionId) {
      setCookie({
        name: AMPLITUDE_SESSION_ID_COOKIE_NAME,
        value: String(amplitudeSessionId),
        expiresIn: 30 * 60 * 1000, //30 minutes
        domain: isPreview ? undefined : '.creativefabrica.com',
      })
    }
  }, [amplitudeCookieDeviceId, amplitudeCookieSessionId])

  React.useEffect(() => {
    if (isLoggedInQuery.isFetching || userId.isFetching) {
      return
    }

    if (isLoggedInQuery.data && userId.data) {
      const user = `user-${userId.data}`
      const amplitudeUser = getAmplitudeUser()

      if (amplitudeUser !== user) {
        setAmplitudeUser(user)
      }
    } else {
      setAmplitudeUser(null)
    }
  }, [isLoggedInQuery.isFetching, isLoggedInQuery.data, userId.isFetching, userId.data])

  return null
}
