'use client'

import * as React from 'react'
import { tw } from 'utils/classnames'
import { useLocale, useTranslations } from 'next-intl'
import { usePathname } from 'next/navigation'

import { QuestionRefreshIcon } from '@ask-cf/assets/icons'
import { BannerContext } from '@ask-cf/contexts/banner-context'
import { StyledLink } from '../styled-link'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { QuestionInputWrapper } from './components/question-input-wrapper'
import { SearchBannerAnimation } from '../lottie/search-banner-animation'

export function SearchBanner() {
  const locale = useLocale()
  const pathname = usePathname()
  const t = useTranslations('ask_cf.components.search_banner')
  const { bannerActive } = React.useContext(BannerContext)
  const isHomePath = pathname === ROUTES.HOME(locale)

  return (
    <section
      id="search-banner"
      className={tw(
        'w-full self-start p-4 pb-0 xl:px-[64px] xl:pt-8',
        bannerActive && 'px-0 py-0 xl:px-0 xl:py-0',
      )}
    >
      <div className="pr-[--removed-body-scroll-bar-size]">
        <div
          id="banner-container"
          className={tw(
            'rounded-4 relative flex w-full flex-row transition-all duration-300',
            'h-[124px]',
            'xl:h-[371px]',
            'xl:mx-auto xl:max-w-[1311px]',
            'bg-gradient-to-b from-[#ADC2FF] to-[#D8DEF0]',
            bannerActive &&
              'h-[72px] rounded-none px-6 py-4 xl:h-[96px] xl:w-full xl:max-w-full xl:p-0 xl:px-16 2xl:px-[calc(50vw-655.5px)]',
          )}
        >
          <div
            id="left-search-banner"
            className={tw(
              'z-[2] flex w-full flex-col px-4 py-3',
              'xl:px-[72px] xl:pt-10',
              bannerActive && 'p-0 xl:px-0 xl:py-5',
            )}
          >
            {!bannerActive || isHomePath ? (
              <h1
                id="search-banner-title"
                className={tw(
                  'text-text-primary text-md mb-2 w-[190px] font-semibold',
                  'xl:mb-0 xl:w-[510px] xl:text-[48px] xl:leading-[67.2px]',
                  bannerActive && ' hidden',
                )}
              >
                {t('title')}
              </h1>
            ) : null}
            <QuestionInputWrapper scrollActive={bannerActive} />
            {bannerActive ? null : <QuestionLink />}
          </div>
          <SideAnimation scrollActive={bannerActive} />
        </div>
      </div>
    </section>
  )
}

function QuestionLink() {
  const locale = useLocale()
  const isDisabled = true
  // TODO: this is meant to be dynamic and show a different question each time
  // the page is loaded. For now, it's just a placeholder.
  return (
    <>
      {isDisabled ? null : (
        <StyledLink
          href={ROUTES.HOME(locale)}
          className={tw('group hidden self-start', 'xl:flex xl:flex-grow-0 xl:flex-row xl:gap-3')}
        >
          <QuestionRefreshIcon />
          <p className="text-content-text-link text-lg leading-[25px] group-hover:underline">
            What is sublimation?
          </p>
        </StyledLink>
      )}
    </>
  )
}

function SideAnimation({ scrollActive }: { scrollActive: boolean }) {
  return (
    <div
      className={tw(
        "xl:rounded-tr-4 xl:rounded-br-4 absolute -right-[5px] top-0 h-full w-[53%] overflow-hidden bg-cover bg-center bg-no-repeat max-[1105px]:w-[56%] xl:bg-[url('../assets/images/closet.webp')]",
        scrollActive && 'w-[30%] xl:rounded-br-none xl:rounded-tr-none',
      )}
    >
      <div className="relative h-full w-full">
        <div
          className={tw(
            'absolute right-10 z-[1] md:bottom-[15px] md:right-10 xl:-bottom-[185px]',
            'xl:right-[25%]',
            scrollActive && '-top-[1/2] right-[15%]',
          )}
        >
          <div
            className={tw(
              'ml-auto block w-[75px]',
              'xl:w-full',
              scrollActive && 'hidden xl:ml-auto xl:block xl:w-[220px]',
            )}
          >
            <SearchBannerAnimation fallback={<div className="aspect-[1]" />} />
          </div>
        </div>
      </div>
    </div>
  )
}
