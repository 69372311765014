'use client'
import * as React from 'react'
import * as Dialog from 'primitives/dialog'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'

import { StyledButton } from '@ask-cf/components/styled-button'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { ROUTES } from '@ask-cf/constants/app-routes'

export function NotifyLoginDialogBody() {
  const { message, closeLoginDialog, setCurrentView } = useNotifyLoginDialog()
  const router = useRouter()
  const [currentUrl, setCurrentUrl] = React.useState('')

  function handleLoginClick() {
    if (process.env.NODE_ENV === 'production') {
      router.push(ROUTES.SIGN_UP(currentUrl))
    } else {
      setCurrentView('login')
    }
  }

  React.useEffect(() => {
    if (window) {
      setCurrentUrl(window.location.href)
    }
  }, [])

  const t = useTranslations('ask_cf.components.notify_login_dialog')

  return (
    <section className="rounded-2 relative mx-4 bg-white px-12 py-6 xl:w-[500px] xl:p-10">
      <main>
        <h2 className="text-blue-1000 max-w-[440px] py-12 text-center text-[28px] font-medium">
          {message}
        </h2>
        <StyledButton
          variant="blue"
          size="large"
          className="leading[24px] w-full"
          onClick={handleLoginClick}
        >
          {t('go_to_login')}
        </StyledButton>
      </main>

      <Dialog.CloseX
        className="hover:bg-text-hover right-0 top-0 z-50 h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white"
        onClick={closeLoginDialog}
      />
    </section>
  )
}
