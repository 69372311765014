export function formatNumber(value: number): string {
  const roundToDecimal = (num: number) => Math.round(num * 10) / 10

  if (value >= 1_000_000) {
    return `${roundToDecimal(value / 1_000_000)}M`
  }
  if (value >= 1000) {
    return `${roundToDecimal(value / 1000)}K`
  }
  return `${roundToDecimal(value)}`
}

export function randomBetween(min: number, max: number) {
  return Math.random() * (max - min) + min
}
