import { ElementTransformer, ORDERED_LIST, UNORDERED_LIST } from '@lexical/markdown'

export const customOrderedListTransformer: ElementTransformer = {
  ...ORDERED_LIST,
  export: (node, traverseChildren): string | null => {
    // Use the original export function
    const originalExport = ORDERED_LIST.export
    const result = originalExport ? originalExport(node, traverseChildren) : null

    if (result !== null && node.getNextSibling() !== null) {
      // Append an extra newline to terminate the list
      return `${result}\n`
    }

    return result
  },
}

export const customUnorderedListTransformer: ElementTransformer = {
  ...UNORDERED_LIST,
  export: (node, traverseChildren): string | null => {
    // Use the original export function
    const originalExport = UNORDERED_LIST.export
    const result = originalExport ? originalExport(node, traverseChildren) : null

    if (result !== null && node.getNextSibling() !== null) {
      // Append an extra newline to terminate the list
      return `${result}\n`
    }

    return result
  },
}
