import invariant from 'tiny-invariant'

const env = process.env.NEXT_PUBLIC_ENV || process.env.VITE_PUBLIC_ENV
const GA4SiteTagId = process.env.NEXT_PUBLIC_GOOGLE_GA4_TAG || process.env.VITE_GOOGLE_GA4_TAG
const globalAdsTagId = process.env.NEXT_PUBLIC_GOOGLE_ADS_TAG || process.env.VITE_GOOGLE_ADS_TAG
const siteTagRegistrationEventId =
  process.env.NEXT_PUBLIC_GOOGLE_SITE_TAG_REGISTRATION_EVENT_ID ||
  process.env.VITE_GOOGLE_SITE_TAG_REGISTRATION_EVENT_ID

invariant(globalAdsTagId, 'GOOGLE_ADS_TAG must be set inside GlobalSiteTag')
invariant(GA4SiteTagId, 'GOOGLE_GA4_TAG must be set inside GlobalSiteTag')

export enum GlobalSiteTagEventName {
  CONVERSION = 'conversion',
  VIEW_CART = 'view_cart',
  DOWNLOAD_PRODUCT = 'Clicked download - triggers file download',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GlobalSiteTagEventOptions = { [key: string]: any } | void
export type GlobalSiteTagEventFunction = (
  event: string,
  name: GlobalSiteTagEventName,
  options: GlobalSiteTagEventOptions,
) => void

declare global {
  interface Window {
    gtag?: GlobalSiteTagEventFunction
  }
}

export const globalSiteSetUserId = (userId: string) => {
  if (typeof gtag === 'function') {
    gtag('config', GA4SiteTagId, {
      user_id: userId,
    })
  }
}

export const globalSiteInit = (userId: string | null) => {
  if (typeof gtag === 'function') {
    if (userId) {
      gtag('config', GA4SiteTagId, {
        user_id: userId,
      })
    } else {
      gtag('config', GA4SiteTagId)
    }
  }
}

export const globalSiteTagEvent = (
  name: GlobalSiteTagEventName,
  options: GlobalSiteTagEventOptions,
) => {
  if (!window.gtag) {
    return
  }

  window.gtag('event', name, options)
}

export const trackRegistrationConversion = () => {
  globalSiteTagEvent(GlobalSiteTagEventName.CONVERSION, {
    send_to: `${GA4SiteTagId}/${siteTagRegistrationEventId}`,
  })
}

export const trackViewCart = (productProps: GlobalSiteTagEventOptions) => {
  globalSiteTagEvent(GlobalSiteTagEventName.VIEW_CART, {
    send_to: `${GA4SiteTagId}/${siteTagRegistrationEventId}`,
    ...productProps,
  })
}

export const googleTrackProductDownloadClick = (eventLabel: string) => {
  globalSiteTagEvent(GlobalSiteTagEventName.DOWNLOAD_PRODUCT, {
    send_to: GA4SiteTagId,
    event_label: eventLabel,
    event_value: null,
    event_category: 'Prod Page',
  })
}

export const initScript = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments)};
gtag('js', new Date());

${env === 'production' ? `gtag('config', '${globalAdsTagId}');` : ''}`

export const scriptUrl = `https://www.googletagmanager.com/gtag/js?id=${GA4SiteTagId}`
