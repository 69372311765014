import Image from 'next/image'

import {
  EmailShareIcon,
  FacebookShareIcon,
  PinterestShareIcon,
} from '@ask-cf/assets/icons/social-share-icons'
import MessengerImg from '../assets/messenger.webp'
import TwitterImg from '../assets/twitter.webp'
import WhatsappImg from '../assets/whatsapp.webp'
import { CF_FACEBOOK_ID } from '@ask-cf/config'

export interface ShareOption {
  label: string
  icon: React.ReactElement
  getHref: (shareUrl: string) => string
}

export const SHARE_OPTIONS: ShareOption[] = [
  {
    label: 'Facebook',
    icon: <FacebookShareIcon />,
    getHref: shareUrl => `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
  },
  {
    label: 'Messenger',
    icon: <Image src={MessengerImg.src} height={32} width={32} alt="Messenger" />,
    getHref: shareUrl =>
      `https://www.facebook.com/dialog/send?app_id=${CF_FACEBOOK_ID}&link=${shareUrl}&redirect_uri=${shareUrl}`,
  },
  {
    label: 'Pinterest',
    icon: <PinterestShareIcon />,
    getHref: shareUrl => `https://pinterest.com/pin/create/button/?url=${shareUrl}`,
  },
  {
    label: 'X (Twitter)',
    icon: <Image src={TwitterImg.src} height={32} width={32} alt="Twitter" />,
    getHref: shareUrl =>
      `https://twitter.com/intent/tweet?text=Check%20out%20this%20question%20on%20Ask%20CF:%20${shareUrl}`,
  },
  {
    label: 'WhatsApp',
    icon: <Image src={WhatsappImg.src} height={32} width={32} alt="WhatsApp" />,
    getHref: shareUrl =>
      `https://api.whatsapp.com/send?text=Check%20out%20this%20question%20on%20Ask%20CF:%20${shareUrl}`,
  },
  {
    label: 'Email',
    icon: <EmailShareIcon />,
    getHref: shareUrl =>
      `mailto:?subject=Check%20out%20this%20question%20on%20Ask%20CF&body=I%20think%20you%20would%20like%20this%20post:%20${shareUrl}`,
  },
]
