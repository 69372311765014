import * as React from 'react'

import { EmojiData } from './emoji-node'

interface EmojiComponentProps {
  emoji: EmojiData
}

export const EmojiComponent: React.FC<EmojiComponentProps> = ({ emoji }) => {
  return <span>{emoji.native}</span>
}
