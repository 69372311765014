import * as React from 'react'
import { addDomEvent } from 'utils/dom'

const mouseEventNames: Record<string, string | undefined> = {
  pointerenter: 'mouseenter',
  pointerleave: 'mouseleave',
}

const supportsPointerEvents = () => typeof window !== 'undefined' && window.onpointerdown === null
const supportsMouseEvents = () => typeof window !== 'undefined' && window.onmousedown === null

function getPointerEventName(name: string): string {
  if (supportsPointerEvents()) {
    return name
  }

  if (supportsMouseEvents() && mouseEventNames[name]) {
    return mouseEventNames[name] as string
  }

  return name
}

// we can use a lazy getter to avoid errors in SSR
type Ref = React.RefObject<EventTarget>
type RefValue = Ref | (() => Window | Document)

export function useDomEvent(
  ref: RefValue,
  eventName: string,
  handler?: EventListener | false,
  options?: AddEventListenerOptions,
) {
  React.useEffect(() => {
    const element = typeof ref === 'function' ? ref() : ref.current

    if (handler && element) {
      return addDomEvent(element, eventName, handler, options)
    }
  }, [ref, eventName, handler, options])
}

useDomEvent.window = () => window
useDomEvent.document = () => document

export function useWindowDomEvent(
  eventName: string,
  handler?: EventListener | false,
  options?: AddEventListenerOptions,
) {
  return useDomEvent(useDomEvent.window, eventName, handler, options)
}

export function usePointerDomEvent(
  ref: RefValue,
  eventName: string,
  handler?: EventListener | false,
  options?: AddEventListenerOptions,
) {
  return useDomEvent(ref, getPointerEventName(eventName), handler, options)
}
