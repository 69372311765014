/* eslint-disable @next/next/no-img-element */

import * as React from 'react'
import { IconButton } from 'primitives/buttons/icon-button'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'
import { useSortable } from '@dnd-kit/sortable'

import { DeleteIcon } from '@ask-cf/assets/icons'

export function SortableItem({
  index,
  imageUrl,
  alt,
  handleDelete,
  imageName,
}: {
  index: number
  imageUrl: string
  alt: string
  handleDelete: (e: React.MouseEvent, index: number) => void
  imageName: string
}) {
  const t = useTranslations('ask_cf.components.image_upload')
  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleDelete(e, index)
  }
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: imageName,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: isDragging ? 'unset' : transition,
        touchAction: 'none', // Ensure touch actions are properly handled
      }
    : { touchAction: 'manipulation' }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div
        key={imageUrl}
        className={tw(
          'rounded-2 flex h-[140px] w-[140px] flex-col items-center justify-center bg-white p-2.5 shadow-[0px_2px_8px_0px_#00000014,_0px_1px_1px_0px_#0000000A]',
        )}
      >
        <img
          src={imageUrl}
          alt={alt}
          height={0}
          style={{
            height: '87px',
            objectFit: 'contain',
            WebkitUserSelect: 'none',
            WebkitTouchCallout: 'none',
          }}
          className="cursor-none select-none rounded-[2px] sm:cursor-default"
        />
        <div className="mt-[7px] flex w-full items-center justify-between text-xs leading-[18px]">
          <span className="text-text-tertiary w-20 select-none overflow-hidden text-ellipsis whitespace-nowrap">
            {imageName}
          </span>
          <IconButton
            variant="transparent"
            onClick={handleDeleteClick}
            className="text-red-500 hover:text-red-400 p-0"
            aria-label={t('delete_aria_label')}
            asChild
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
}
