import Picker from '@emoji-mart/react'
import { CloseButton } from 'primitives/buttons/close-button'
import * as React from 'react'
import { tw } from 'utils/classnames'
import { Portal } from 'primitives/portal'

import { EmojiData } from './emoji-node'

export function EmojiPicker({
  isOpen,
  x,
  y,
  insertEmoji,
  onClose,
  container = null,
}: {
  isOpen: boolean
  x: number
  y: number
  insertEmoji: (emoji: EmojiData, event: MouseEvent) => void
  onClose: () => void
  container?: HTMLElement | null
}) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [position, setPosition] = React.useState({ x, y })
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    function adjustPosition() {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        let newX = x - rect.width
        let newY = y - 0.25 * rect.height - 30
        const bottom = newY + rect.height
        const right = newX + rect.width

        if (rect.width < window.innerWidth) {
          if (right > window.innerWidth) {
            newX = window.innerWidth - rect.width
          }
          if (newX < 10) {
            newX = 10
          }
        }

        if (rect.height < window.innerHeight) {
          if (bottom > window.innerHeight) {
            newY = window.innerHeight - rect.height - 30
          }
          if (newY < 10) {
            newY = 10
          }
        }

        setPosition({ x: newX, y: newY })
      }
    }

    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    let timeoutId: NodeJS.Timeout
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      timeoutId = setTimeout(() => {
        adjustPosition()
        setIsReady(true)
      }, 50)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose, x, y])

  return isOpen ? (
    <Portal
      className={tw(
        isReady
          ? 'opacity-100 transition-opacity duration-300'
          : 'opacity-0 transition-opacity duration-300',
        'pointer-events-auto fixed z-[100] w-[352px] bg-white',
      )}
      style={{ left: position.x, top: position.y }}
      ref={containerRef}
      container={container}
    >
      <CloseButton
        onClick={onClose}
        className="!text-cf-white absolute right-0 top-0 z-50 flex h-5 w-5 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full !bg-[#2266ed] bg-white hover:bg-[#81a1ff]"
      />
      <Picker
        theme="light"
        onEmojiSelect={(emoji: EmojiData, event: MouseEvent) => insertEmoji(emoji, event)}
      />
    </Portal>
  ) : null
}
