import * as React from 'react'

import { toBase64 } from '@ask-cf/utils/files'
import { ImageUploadInput } from './components/image-upload-input'
import { UploadedFile } from '@ask-cf/hooks/use-image-upload'

interface MultiImageProps {
  className?: string
  images: UploadedFile[]
  onFileDelete?: (index: number) => void
  onFileAdd?: (files: UploadedFile[]) => void
  name?: string
  id?: string
  alt?: string
  maxImages?: number
  onFilesReordered: (images: UploadedFile[]) => void
  setLoading?: (loading: boolean) => void
}

export function ImageUpload({
  className,
  images,
  onFileDelete,
  onFileAdd,
  name,
  id = '',
  maxImages,
  onFilesReordered,
  setLoading,
}: MultiImageProps) {
  const [imageUrls, setImageUrls] = React.useState<Map<string, string>>(new Map<string, string>())

  React.useEffect(() => {
    const unifyImages = async () => {
      const result = new Map<string, string>()
      for (const image of images) {
        result.set(image.name, await toBase64(image))
      }
      setImageUrls(result)
    }
    unifyImages()
  }, [images])

  const handleDelete = (e: React.MouseEvent, index: number) => {
    e.stopPropagation()
    e.preventDefault()
    onFileDelete && onFileDelete(index)
  }

  const handleAdd = (files: UploadedFile[]) => {
    if (!maxImages || images.length < maxImages) {
      onFileAdd && onFileAdd(files)
    }
  }

  return (
    <div className={className}>
      <ImageUploadInput
        handleFile={handleAdd}
        name={name}
        id={id}
        currentFileCount={images.length}
        images={images}
        onFilesReordered={onFilesReordered}
        handleDelete={handleDelete}
        imageUrls={imageUrls}
        setLoading={setLoading}
      />
    </div>
  )
}
