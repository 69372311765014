export type LOCALES = 'en' | 'de' | 'fr' | 'it' | 'nl' | 'es' | 'pt' | 'pl'

export const locales = ['en', 'de', 'fr', 'it', 'nl', 'es', 'pt', 'pl']
export const DEFAULT_LOCALE = 'en'

const validateLocale = (locale: string) => locales.includes(locale)

export const mapStringToLocale = (locale: string) => {
  if (!validateLocale(locale)) {
    return DEFAULT_LOCALE
  }

  return locale as LOCALES
}
