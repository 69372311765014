'use client'

import * as React from 'react'
import { useLocale, useTranslations } from 'next-intl'
import { useUserCoinBalanceQuery } from 'codegen/generated/coins'
import { SkeletonItem } from 'components/skeleton-item'
import { IconButton } from 'primitives/buttons/icon-button'
import { useIsLoggedInQuery } from 'auth/hooks'

import { CoinIcon } from '@ask-cf/assets/icons'
import { StyledLink } from '@ask-cf/components/styled-link'
import { useUser } from '@ask-cf/hooks/use-user-data'
import { LanguageSelect } from './language-switcher'
import { CurrentUserAvatar } from '@ask-cf/components/avatar'
import { LoginButton } from '@ask-cf/components/login-button'
import { SignUpButton } from '@ask-cf/components/sign-up-button'
import { NotificationsMenu } from './notifications-menu'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { ASK_CF_FF_LANGUAGE_SWITCHER, ASK_CF_FF_NOTIFICATIONS } from '@ask-cf/config'

export function UserHeaderSection({ onUserMenuOpen }: { onUserMenuOpen: () => void }) {
  const t = useTranslations('ask_cf.components.navbar.auth')
  const user = useUser()
  // eslint-disable-next-line local/codegen-query-patterns
  const isUserLoggedIn = useIsLoggedInQuery()
  // eslint-disable-next-line local/codegen-query-patterns
  const userBalance = useUserCoinBalanceQuery()
  const locale = useLocale()
  const formattedBalance = React.useMemo(
    () => userBalance.data?.userBalance.balance.toLocaleString('en-US'),
    [userBalance.data?.userBalance.balance],
  )

  return (
    <div className="flex flex-row items-center gap-4 whitespace-nowrap">
      {user.isLoading ? (
        <UserHeaderSectionSkeleton />
      ) : !isUserLoggedIn.data ? (
        <>
          <SignUpButton spanClassName="hidden xl:flex" />
          <LoginButton spanClassName="hidden xl:flex" />
        </>
      ) : (
        <>
          {ASK_CF_FF_NOTIFICATIONS ? <NotificationsMenu /> : null}
          <StyledLink
            href={ROUTES.COINS(locale)}
            target="_blank"
            aria-label={t('coins_link_aria_label')}
          >
            <CoinIcon />
            <span className="ml-1 text-sm">{formattedBalance}</span>
          </StyledLink>
          <StyledLink
            href={ROUTES.MY_ACCOUNT}
            target="_blank"
            className="tablet:flex hidden"
            aria-label={t('my_account_link_aria_label')}
          >
            <CurrentUserAvatar size="small" />
            <span className="ml-1 text-sm">{t('profile_link')}</span>
          </StyledLink>
          <IconButton
            className="tablet:hidden bg-transparent hover:bg-transparent active:bg-transparent"
            onClick={onUserMenuOpen}
            aria-label={t('profile_link_aria_label')}
            asChild
          >
            <CurrentUserAvatar size="small" />
          </IconButton>
        </>
      )}
      {ASK_CF_FF_LANGUAGE_SWITCHER ? <LanguageSelect className="hidden xl:flex" /> : null}
    </div>
  )
}

function UserHeaderSectionSkeleton() {
  return (
    <>
      <SkeletonItem className="w-[30px]" />
      <SkeletonItem className="w-[60px]" />
      <SkeletonItem className="w-[97px]" />
    </>
  )
}
