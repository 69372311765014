import { Spread, DecoratorNode, SerializedLexicalNode, DOMExportOutput, LexicalNode } from 'lexical'

import { EmojiComponent } from './emoji-component'

export type SerializedEmojiNode = Spread<
  {
    emoji: EmojiData
    key?: string
  },
  SerializedLexicalNode
>

export interface EmojiData {
  native: string
}

export class EmojiNode extends DecoratorNode<object> {
  __emoji: EmojiData

  constructor(emoji: EmojiData, key?: string) {
    super(key)
    this.__emoji = emoji
  }

  static getType() {
    return 'emoji'
  }

  static clone(node: EmojiNode): EmojiNode {
    return new EmojiNode(node.__emoji, node.__key)
  }

  getEmoji(): EmojiData {
    return this.__emoji
  }

  createDOM(): HTMLElement {
    const span = document.createElement('span')
    return span
  }

  exportDOM(): DOMExportOutput {
    const span = this.createDOM()
    span.innerText = this.__emoji.native
    span.setAttribute('data-lexical-decorator', 'true')
    span.setAttribute('contenteditable', 'false')
    return { element: span }
  }

  updateDOM(): boolean {
    return false
  }

  decorate(): JSX.Element {
    return <EmojiComponent emoji={this.__emoji} />
  }

  static importJSON(serializedNode: SerializedEmojiNode): EmojiNode {
    const { emoji, key } = serializedNode
    return new EmojiNode(emoji as EmojiData, key)
  }

  exportJSON(): SerializedEmojiNode {
    return {
      ...super.exportJSON(),
      emoji: this.__emoji,
      key: this.__key,
    }
  }

  static importDOM(): null {
    return null
  }
}

export const $isEmojiNode = (node: LexicalNode): boolean => {
  return node.__type === 'emoji'
}
