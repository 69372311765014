import * as Dialog from 'primitives/dialog'
import * as React from 'react'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import {
  useAskCfDiscardQuestionMutation,
  useAskCfConfirmQuestionMutation,
} from 'codegen/generated/ask-cf'

import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { ConfirmationBannerMobile } from './confirmation-banner-mobile'
import OtherTopicConfirmationImage from '../assets/other-topic-confirmation.webp'
import { StyledButton } from '@ask-cf/components/styled-button'

export function QuestionDialogOtherTopicConfirmation({
  onNext,
  onBackToEditQuestion,
  questionId,
}: {
  onNext: () => void
  onBackToEditQuestion: () => void
  questionId: string
}) {
  const { closeClearModalData } = useQuestionDialog()
  const t = useTranslations('ask_cf.components.question_dialog.confirmations.other_topic')
  const askCfDiscardQuestionMutation = useAskCfDiscardQuestionMutation()
  const askCfConfirmQuestionMutation = useAskCfConfirmQuestionMutation()

  function handleDiscardQuestion() {
    askCfDiscardQuestionMutation.mutate(
      { input: { questionId: questionId } },
      { onSuccess: () => onBackToEditQuestion() },
    )
  }

  function handleConfirmQuestion() {
    askCfConfirmQuestionMutation.mutate(
      { input: { questionId: questionId } },
      { onSuccess: () => onNext() },
    )
  }

  return (
    <div className="xl:rounded-2 relative flex h-full w-full flex-col bg-blue-400 pt-4 xl:h-[530px] xl:max-w-[390px] xl:flex-row xl:pt-0">
      <div className={'shrink-0 xl:hidden'}>
        <ConfirmationBannerMobile onClose={closeClearModalData} title={t('title')} />
      </div>
      <div className="rounded-t-4 xl:rounded-2 z-10 flex grow flex-col items-center overflow-y-auto bg-white px-6 py-5 xl:pt-10">
        <div className="text-blue-1000 mb-1 hidden text-center text-[28px] font-medium leading-[39.2px] xl:block xl:max-w-[380px]">
          {t('title')}
        </div>
        <Image
          width={503}
          height={386}
          src={OtherTopicConfirmationImage}
          alt="Other topic"
          className="-mb-[30px] -mt-[100px]"
        />
        <div className="mt-10 text-center text-sm leading-5 text-gray-600 xl:max-w-[320px]">
          {t('content')}
        </div>
        <div className="mt-auto flex flex-col items-center">
          <StyledButton
            variant="gray"
            className="py-2 text-sm font-semibold leading-[24px] text-blue-700 xl:h-[56px] xl:w-[328px]"
            onClick={handleDiscardQuestion}
          >
            {t('back_button_label')}
          </StyledButton>
          <StyledButton
            variant="blue"
            className="mt-4 py-2 text-sm font-semibold leading-[24px] xl:h-[56px] xl:w-[328px]"
            onClick={handleConfirmQuestion}
          >
            {t('next_button_label')}
          </StyledButton>
        </div>
        <Dialog.CloseX className="hover:bg-text-hover right-0 top-0 z-50 hidden h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white xl:flex" />
      </div>
    </div>
  )
}
