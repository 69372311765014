import * as React from 'react'
import { useRouter } from 'next/navigation'
import { useLocale } from 'next-intl'

import { QuestionDialogFormView } from './form-view'
import { QuestionDialogStandardConfirmation } from './standard-confirmation'
import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { QuestionDialogOtherTopicConfirmation } from './other-topic-confirmation'
import { QuestionDialogSupportTopicConfirmation } from './support-topic-confirmation'
import { QuestionDialogSupportRequestSubmittedConfirmation } from './support-request-submitted-confirmation'
import { ROUTES } from '@ask-cf/constants/app-routes'

export interface Question {
  question: string
  topic: string
  uploadUrls: string[]
  id: string
  images: File[]
  topicClassifierTimeout: boolean
  slug: string
  topicSlug: string
}

export function QuestionDialogBody() {
  const { closeClearModalData } = useQuestionDialog()
  const [currentView, setCurrentView] = React.useState<
    | 'form'
    | 'confirmation-standard'
    | 'ASK_CF_TOPIC_NAME_OTHER'
    | 'ASK_CF_TOPIC_NAME_SUPPORT_QUESTIONS'
    | 'confirmation-support-request-submitted'
    | string
  >('form')
  const [question, setQuestion] = React.useState<Question | null>(null)
  const [skipConfirmation, setSkipConfirmation] = React.useState(() => {
    const storedValue = localStorage.getItem('skipConfirmation')
    const initialValue = storedValue !== null ? storedValue === 'true' : false

    if (storedValue === null) {
      localStorage.setItem('skipConfirmation', 'false')
    }

    return initialValue
  })
  const router = useRouter()
  const locale = useLocale()

  function handleOnNext(question: Question) {
    setQuestion(question)
    if (
      (question?.topic === 'ASK_CF_TOPIC_NAME_OTHER' && !question.topicClassifierTimeout) ||
      question?.topic === 'ASK_CF_TOPIC_NAME_SUPPORT_QUESTIONS'
    ) {
      setCurrentView(question.topic)
    } else {
      setCurrentView('confirmation-standard')
    }
  }

  function redirectToQuestion() {
    closeClearModalData()
    router.push(ROUTES.QUESTION_DETAILS(question?.topicSlug || '', question?.slug || '', locale))
  }

  function handleSkipConfirmationToggle() {
    setSkipConfirmation(prevState => {
      const newSkipValue = !prevState
      localStorage.setItem('skipConfirmation', String(newSkipValue))
      return newSkipValue
    })
  }

  React.useEffect(() => {
    // Only redirect if skipConfirmation is true and question is not null AND we're not on the views for other or support questions
    if (skipConfirmation && question && currentView === 'confirmation-standard') {
      redirectToQuestion()
    }
  }, [question, currentView]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {currentView === 'form' ? (
        <QuestionDialogFormView onNext={handleOnNext} questionId={question?.id || ''} />
      ) : null}
      {currentView === 'confirmation-standard' ? (
        <QuestionDialogStandardConfirmation
          onNext={redirectToQuestion}
          skipConfirmation={skipConfirmation}
          toggleSkipConfirmation={handleSkipConfirmationToggle}
        />
      ) : null}
      {currentView === 'ASK_CF_TOPIC_NAME_OTHER' ? (
        <QuestionDialogOtherTopicConfirmation
          onNext={() => setCurrentView('confirmation-standard')}
          onBackToEditQuestion={() => setCurrentView('form')}
          questionId={question?.id || ''}
        />
      ) : null}
      {currentView === 'ASK_CF_TOPIC_NAME_SUPPORT_QUESTIONS' ? (
        <QuestionDialogSupportTopicConfirmation
          questionId={question?.id || ''}
          onSupportSubmitNext={() => setCurrentView('confirmation-support-request-submitted')}
          onConfirmPostNext={() => setCurrentView('confirmation-standard')}
        />
      ) : null}
      {currentView === 'confirmation-support-request-submitted' ? (
        <QuestionDialogSupportRequestSubmittedConfirmation onNext={closeClearModalData} />
      ) : null}
    </>
  )
}
