import * as Dialog from 'primitives/dialog'
import * as React from 'react'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { ConfirmationBannerMobile } from './confirmation-banner-mobile'
import SupportRequestSubmittedConfirmationImage from '../assets/support-request-submitted-confirmation.webp'
import { StyledButton } from '@ask-cf/components/styled-button'

export function QuestionDialogSupportRequestSubmittedConfirmation({
  onNext,
}: {
  onNext: () => void
}) {
  const { closeDialog } = useQuestionDialog()
  const t = useTranslations(
    'ask_cf.components.question_dialog.confirmations.support_request_submitted',
  )

  return (
    <div className="xl:rounded-2 relative flex h-full w-full flex-col bg-blue-400 pt-4 xl:h-[520px] xl:max-w-[430px] xl:flex-row xl:pt-0">
      <div className={'shrink-0 xl:hidden'}>
        <ConfirmationBannerMobile onClose={closeDialog} title={t('title')} />
      </div>
      <div className="rounded-t-4 xl:rounded-2 z-10 flex grow flex-col items-center overflow-y-auto bg-white px-6 py-5 xl:pt-10">
        <div className="text-blue-1000 mb-1 hidden text-center text-[28px] font-medium leading-[39.2px] xl:block">
          {t('title')}
        </div>
        <Image
          width={219}
          height={213}
          src={SupportRequestSubmittedConfirmationImage}
          alt="Ask support"
          className="mt-6"
        />
        <div className="mt-6 text-center text-sm leading-5 text-gray-600 xl:max-w-[330px]">
          {t('content')}
        </div>
        <div className="mt-auto flex flex-col items-center">
          <StyledButton
            variant="gray"
            className="py-2 text-sm font-semibold leading-[24px] text-blue-700 xl:h-[56px] xl:w-[328px]"
            onClick={onNext}
          >
            {t('close_button_label')}
          </StyledButton>
        </div>
        <Dialog.CloseX className="hover:bg-text-hover right-0 top-0 z-50 hidden h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white xl:flex" />
      </div>
    </div>
  )
}
