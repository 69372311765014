import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import * as React from 'react'
import {
  $convertToMarkdownString,
  ELEMENT_TRANSFORMERS,
  INLINE_CODE,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  HIGHLIGHT,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  STRIKETHROUGH,
  TEXT_MATCH_TRANSFORMERS,
} from '@lexical/markdown'

import { EMOJI_ELEMENT_TRANSFORMER, EMOJI_TRANSFORMER } from './transformers/emoji.transformer'
import { UNDERLINE } from './transformers/underline.transformer'
import {
  customOrderedListTransformer,
  customUnorderedListTransformer,
} from './transformers/list.transformer'

export const ALL_TRANSFORMERS = [
  EMOJI_TRANSFORMER,
  EMOJI_ELEMENT_TRANSFORMER,
  customOrderedListTransformer,
  customUnorderedListTransformer,
  ...ELEMENT_TRANSFORMERS,
  UNDERLINE,
  INLINE_CODE,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  HIGHLIGHT,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  STRIKETHROUGH,
  ...TEXT_MATCH_TRANSFORMERS,
]

export function OnChangeHtmlPlugin({
  onChange,
  initialValue,
}: {
  onChange?: (editorHtml: string) => void
  initialValue?: string
}) {
  const [editor] = useLexicalComposerContext()
  const initialValueSet = React.useRef<boolean>(false)

  React.useEffect(() => {
    const unregister = editor.registerUpdateListener(() => {
      editor.update(() => {
        if (initialValue && !initialValueSet.current) {
          initialValueSet.current = true
        } else {
          const markdown = $convertToMarkdownString(ALL_TRANSFORMERS, undefined, true)
          if (onChange) {
            onChange(markdown)
          }
        }
      })
    })

    return () => {
      unregister()
    }
  }, [editor, onChange, initialValue])

  return null
}
