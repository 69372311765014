import { Language } from 'codegen/types'
import * as React from 'react'
import { useUserSettingsQuery } from 'codegen/generated/ask-cf'

import { languages } from '@ask-cf/constants/languages'

export function LocaleTracker() {
  const { data: userLanguage } = useUserSettingsQuery(undefined, {
    select: data => data.me?.user?.language,
  })

  React.useEffect(() => {
    const navigateToNewLocale = (newLanguage: Language | undefined) => {
      if (!document || !newLanguage) {
        return
      }
      const location = document.location.pathname
      const languageSegment = location.split('/')[1]
      const hasLocationSegment = languages.includes(languageSegment as Language)

      const language = newLanguage === 'en' ? '' : `/${newLanguage}`

      const newLocation = hasLocationSegment
        ? location.replace(`/${languageSegment}/`, `${language}/`)
        : `${language}${location}`

      document.cookie = `lang=${newLanguage}; domain=.creativefabrica.com; path=/`

      if (location !== newLocation) {
        document.location.pathname = newLocation
      }
    }
    navigateToNewLocale(userLanguage)
  }, [userLanguage])
  return null
}
