export const toBase64 = (file?: File): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) {
      return resolve('')
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
  })

export const fromBase64 = async (base64: string, filename: string, filetype = 'image/png') => {
  const fileResponse = await fetch(base64)
  const blob = await fileResponse.blob()
  const file = new File([blob], filename, { type: filetype })
  return file
}
