import amplitude, { AmplitudeClient } from 'amplitude-js'
import invariant from 'tiny-invariant'

const AMPLITUDE_API_KEY =
  process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? import.meta.env.VITE_AMPLITUDE_API_KEY

invariant(
  AMPLITUDE_API_KEY,
  'VITE_AMPLITUDE_API_KEY or NEXT_PUBLIC_AMPLITUDE_API_KEY must be set within packages/analytics/amplitude/utils.ts',
)

export enum AmplitudeEvents {
  PAGE_VIEW = 'Loaded a Page',
  NOTIFICATION_CLICK = 'Web Notification Clicked',
  LOGIN_MODAL_OPENED = 'Opened Login Modal',
  REGISTRATION_MODAL_OPENED = 'Opened Register Modal',
  CLICKED_BANNER_ABOVE_NAV = 'Clicked Banner Above Nav',
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  DOWNLOADED_PRODUCT = 'Downloaded Product',
  PRODUCTS_SEARCHED = 'Products Searched',
  CLICKED_PAYMENT_MODAL = 'Clicked Payment Modal',
  VIEWED_PAYMENT_MODAL = 'Viewed Payment Modal',
  CLOSED_PAYMENT_MODAL = 'Closed Payment Modal',
  CLICKED_CONFIRMED_AND_PAID = 'Clicked Confirmed and Paid Payment Modal',
  CART_VIEWED = 'Cart Viewed',
  PROJECT_CREATED = 'Studio Project Created',
}

export enum AuthModalLocations {
  SITE_HEADER = 'nav menu',
  LOGIN_MODAL = 'login modal',
  REGISTRATION_MODAL = 'registration modal',
  CART = 'cart',
}

let AMPLITUDE_INITIALIZED = false

export const initAmplitude = async (deviceId: string | undefined) => {
  if (typeof window === 'undefined' || AMPLITUDE_INITIALIZED) {
    return
  }

  if (deviceId) {
    amplitude.getInstance().setDeviceId(deviceId)
  }

  amplitude.getInstance().init(AMPLITUDE_API_KEY, undefined, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    saveParamsReferrerOncePerSession: true,
    unsetParamsReferrerOnNewSession: true,
  })

  AMPLITUDE_INITIALIZED = true
}

export const sendAmplitudeEvent = (
  eventName: AmplitudeEvents,
  eventProperties?: Record<string, unknown>,
) => {
  if (typeof window === 'undefined' || !AMPLITUDE_INITIALIZED) {
    return
  }

  const defaultProperties = {
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.href,
  }

  amplitude.getInstance().logEvent(eventName, { ...defaultProperties, ...eventProperties })
}

export const setAmplitudeUser = (userId: string | null) => {
  if (typeof window === 'undefined' || !AMPLITUDE_INITIALIZED) {
    return
  }
  amplitude.getInstance().setUserId(userId)
}

export const getAmplitudeUser = () => {
  if (typeof window === 'undefined' || !AMPLITUDE_INITIALIZED) {
    return
  }
  return amplitude.getInstance().getUserId()
}

export const setAmplitudeDeviceId = (deviceId: string) => {
  if (typeof window === 'undefined' || !AMPLITUDE_INITIALIZED) {
    return
  }
  amplitude.getInstance().setDeviceId(deviceId)
}

export enum AmplitudeEvent {
  PAGE_VIEW = 'Loaded a Page',
  NOTIFICATION_CLICK = 'Web Notification Clicked',
  CHECKOUT_START = 'Checkout Started',
  LOGIN_MODAL_OPENED = 'Opened Login Modal',
  REGISTRATION_MODAL_OPENED = 'Opened Register Modal',
  IMAGEMIX_UPLOAD_FAILED = 'ImageMix Upload Failed',
  IMAGEUPSCAPER_UPLOAD_FAILED = 'Image Upscaler Upload Failed',
  CLICKED_BANNER_ABOVE_NAV = 'Clicked Banner Above Nav',
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  DOWNLOADED_PRODUCT = 'Downloaded Product',
  PRODUCTS_SEARCHED = 'Products Searched',
  PROJECT_CREATED = 'Studio Project Created',
}

type AmplitudeProperty = string | number | string[] | number[]

export type AmplitudeLogEvent = (
  name: AmplitudeEvent,
  properties: Record<
    string,
    AmplitudeProperty | Record<string, AmplitudeProperty> | Record<string, AmplitudeProperty>[]
  >,
) => void
export interface AmplitudeClientAugmented extends AmplitudeClient {
  _isInitialized: boolean
}

export const sendAmplitudeLog: AmplitudeLogEvent = (name, properties) => {
  const amplitudeInstance = amplitude.getInstance() as AmplitudeClientAugmented

  if (!amplitudeInstance._isInitialized) {
    return
  }
  const defaultProperties = {
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.href,
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  amplitude.getInstance().logEvent(name, { ...defaultProperties, ...properties }, function () {})
}

export const getAmplitudeDeviceId = () => {
  if (typeof window === 'undefined' || !AMPLITUDE_INITIALIZED) {
    return
  }

  return amplitude.getInstance().getDeviceId()
}

export const getAmplitudeSessionId = () => {
  if (typeof window === 'undefined' || !AMPLITUDE_INITIALIZED) {
    return
  }

  return amplitude.getInstance().getSessionId()
}

export const trackPageView = (props: {
  banner_above_nav: string
  banner_above_nav_state: string
}) => {
  sendAmplitudeEvent(AmplitudeEvents.PAGE_VIEW, props)
}

export const trackLoginModalOpen = (
  location: AuthModalLocations = AuthModalLocations.SITE_HEADER,
) => {
  sendAmplitudeEvent(AmplitudeEvents.LOGIN_MODAL_OPENED, {
    modal_type: 'login',
    location: location,
  })
}

export const trackRegistrationModalOpen = (
  location: AuthModalLocations = AuthModalLocations.SITE_HEADER,
) => {
  sendAmplitudeEvent(AmplitudeEvents.REGISTRATION_MODAL_OPENED, {
    modal_type: 'registration',
    location: location,
  })
}

export const trackStudioProjectCreation = (data: {
  user_ID?: string
  user_type: 'identified' | 'anon'
}) => {
  sendAmplitudeEvent(AmplitudeEvents.PROJECT_CREATED, data)
}

export const genRanHex = (size: number) =>
  [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

export const trackCartViewed = (eventProperties?: Record<string, unknown>) => {
  sendAmplitudeEvent(AmplitudeEvents.CART_VIEWED, {
    ...eventProperties,
    cart_id: eventProperties?.cart_id ?? `${genRanHex(32)}_${Date.now()}`,
  })
}
