import * as SwitchPrimitive from 'primitives/switch'
import { tw } from 'utils/classnames'

export function Switch(props: SwitchPrimitive.SwitchProps) {
  return (
    <SwitchPrimitive.Root
      {...props}
      className={tw(
        'relative h-[24px] w-[48px] rounded-full px-[3px]',
        'border-text-quinary border bg-blue-200',
        'data-[state="checked"]:border-0 data-[state="checked"]:bg-blue-700',
        props.className,
      )}
    >
      <SwitchPrimitive.Thumb
        className={tw(
          'block rounded-full px-1 transition-transform duration-[300ms]',
          'bg-text-quinary h-[13px] w-[13px] translate-x-0',
          'data-[state="checked"]:h-[18px] data-[state="checked"]:w-[18px] data-[state="checked"]:translate-x-[23px] data-[state="checked"]:bg-white',
        )}
      />
    </SwitchPrimitive.Root>
  )
}
