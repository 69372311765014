'use client'

import { tw } from 'utils/classnames'
import * as React from 'react'

import { BannerContext } from '@ask-cf/contexts/banner-context'

export function MainContent({
  children,
  breadcrumbs,
}: {
  children: React.ReactNode
  breadcrumbs?: React.ReactNode
}) {
  const { bannerActive } = React.useContext(BannerContext)

  return (
    <div
      id="main-content"
      className={tw(
        'px-4 pb-[52px] xl:px-[64px] xl:pb-[48px] 2xl:px-[calc(calc(100dvw-1311px)/2)]',
        bannerActive ? 'pt-[146px] xl:pt-[204px]' : 'pt-[208px] xl:pt-[511px]',
      )}
    >
      {breadcrumbs}
      <div className="flex flex-grow items-center">
        {/* Fix for context not updating the component here, if anyone has a better idea please let me know */}
        {bannerActive ? ' ' : null}
        {children}
      </div>
    </div>
  )
}
