import { LexicalNode, ParagraphNode, TextNode } from 'lexical'
import { TextMatchTransformer, ElementTransformer } from '@lexical/markdown'

import { $isEmojiNode, EmojiData, EmojiNode } from '../../emoji-plugin/emoji-node'

export const EMOJI_TRANSFORMER: TextMatchTransformer = {
  export: (node: LexicalNode): string | null => {
    if (!$isEmojiNode(node)) {
      return null
    }

    const emojiNode = node as EmojiNode
    return `\`\`\`${emojiNode.getEmoji().native}\`\`\``
  },
  regExp: /```([^|]+)```/,
  importRegExp: /```([^|]+)```/,
  replace: (textNode: LexicalNode, match: string[]): void => {
    const [, emojiNative] = match

    const emojiData: EmojiData = { native: emojiNative }
    const emojiNode = new EmojiNode(emojiData)

    textNode.replace(emojiNode)
  },
  type: 'text-match',
  trigger: 'on-change',
  dependencies: [EmojiNode],
}

export const EMOJI_ELEMENT_TRANSFORMER: ElementTransformer = {
  export: (node: LexicalNode): string | null => {
    if (!$isEmojiNode(node)) {
      return null
    }

    const emojiNode = node as EmojiNode
    return `\`\`\`${emojiNode.getEmoji().native}\`\`\``
  },
  regExp: /```([^|]+)```/,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replace: (parentNode: LexicalNode, children: LexicalNode[], match: any) => {
    const [emojiWithTags, emojiNative] = match
    const input = match.input
    const emojiIndex = input.indexOf(emojiWithTags)
    const emojiLength = emojiWithTags.length

    const emojiData: EmojiData = { native: emojiNative }
    const emojiNode = new EmojiNode(emojiData)
    const paragraphNode = new ParagraphNode()
    const textBeforeNode = new TextNode(input.slice(0, emojiIndex))
    const textAfterNode = new TextNode(input.slice(emojiIndex + emojiLength))
    paragraphNode.append(textBeforeNode, emojiNode, textAfterNode)
    parentNode.replace(paragraphNode)
  },
  type: 'element',
  dependencies: [EmojiNode],
}
