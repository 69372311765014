import { invariant } from 'utils/invariant'
import algoliasearch, { type SearchClient } from 'algoliasearch/lite'
import algoliaAnalytics from 'search-insights'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import * as React from 'react'
import ms from 'ms'
import { Index, InstantSearch, useInstantSearch, Configure } from 'react-instantsearch'

const algoliaIndex = process.env.NEXT_PUBLIC_ALGOLIA_INDEX
const algoliaAppId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
const algoliaApiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY

invariant(algoliaAppId, 'NEXT_PUBLIC_ALGOLIA_APP_ID is not set')
invariant(algoliaApiKey, 'NEXT_PUBLIC_ALGOLIA_API_KEY is not set')

const defaultSearchClient = algoliasearch(algoliaAppId, algoliaApiKey)

algoliaAnalytics('init', {
  appId: algoliaAppId,
  apiKey: algoliaApiKey,
  useCookie: true,
  cookieDuration: ms('90 days'),
})

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch()

  React.useEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: algoliaAnalytics,
    })

    return addMiddlewares(middleware)
  }, [addMiddlewares])

  return null
}

interface AlgoliaProviderProps
  extends Omit<React.ComponentProps<typeof InstantSearch>, 'searchClient'> {
  searchClient?: SearchClient
  hitsPerPage?: number
  page?: number
}

export function AlgoliaProvider({
  children,
  indexName = algoliaIndex,
  searchClient = defaultSearchClient,
  hitsPerPage = 100,
  page = 1,
  ...props
}: AlgoliaProviderProps) {
  invariant(indexName, 'indexName is not set within <AlgoliaProvider />')

  return (
    <InstantSearch {...props} searchClient={searchClient} indexName={indexName}>
      {children}
      <Configure facets={['*']} hitsPerPage={hitsPerPage} page={page} />
      <InsightsMiddleware />
    </InstantSearch>
  )
}

export function AlgoliaIndex({
  children,
  indexName = algoliaIndex,
}: React.PropsWithChildren<{ indexName?: string }>) {
  invariant(indexName, 'indexName is not set within <AlgoliaIndex />')

  return <Index indexName={indexName}>{children}</Index>
}

export function NoResultsBoundary({
  children,
  fallback,
}: {
  children: React.ReactNode
  fallback: React.ReactNode
}) {
  const { results } = useInstantSearch()

  // The `__isArtificial` flag makes sure to not display the No Results message
  // when no hits have been returned yet.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }

  return <>{children}</>
}
