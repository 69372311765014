'use client'
import * as React from 'react'
import * as Dialog from 'primitives/dialog'

import { ReportQuestionDialogBody } from './components/report-question-dialog-body'
import { useReportQuestionDialog } from '@ask-cf/contexts/report-question-dialog-context'

export function ReportQuestionDialog() {
  const { isOpen, closeDialog } = useReportQuestionDialog()

  return (
    <Dialog.Root open={isOpen} onOpenChange={open => !open && closeDialog()} defaultOpen={false}>
      <React.Suspense fallback={null}>
        <Dialog.Portal>
          <Dialog.Body className="font-poppins z-50 xl:overflow-y-auto xl:py-10" overlayZIndex={49}>
            <ReportQuestionDialogBody />
          </Dialog.Body>
        </Dialog.Portal>
      </React.Suspense>
    </Dialog.Root>
  )
}
