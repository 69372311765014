'use client'

import * as React from 'react'

export interface ReportQuestionDialogContextModel {
  isOpen: boolean
  postId: string | null
  openDialog: (postId: string) => void
  closeDialog: () => void
}

const ReportQuestionDialogContext = React.createContext<ReportQuestionDialogContextModel>(null!)

export const ReportQuestionDialogProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [postId, setPostId] = React.useState<string | null>(null)

  const openDialog = (postId: string) => {
    setIsOpen(true)
    setPostId(postId)
  }

  const closeDialog = () => {
    setIsOpen(false)
    setPostId(null)
  }

  return (
    <ReportQuestionDialogContext.Provider value={{ isOpen, postId, closeDialog, openDialog }}>
      {children}
    </ReportQuestionDialogContext.Provider>
  )
}

export const useReportQuestionDialog = () => {
  const context = React.useContext(ReportQuestionDialogContext)

  if (context === undefined) {
    throw new Error('useReportQuestionDialog must be used within a ReportQuestionDialogProvider')
  }

  return context
}
