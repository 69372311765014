import * as React from 'react'
import * as Dialog from 'primitives/dialog'
import { useTranslations } from 'next-intl'

import { FlagIcon } from '@ask-cf/assets/icons'
import { useReportQuestionDialog } from '@ask-cf/contexts/report-question-dialog-context'
import { StyledButton } from '@ask-cf/components/styled-button'

export function ReportQuestionDialogBody() {
  const t = useTranslations('ask_cf.components.report_question_dialog')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { closeDialog, postId } = useReportQuestionDialog()

  return (
    <section className="rounded-2 relative mx-4 bg-white px-2 py-6 xl:w-[669px] xl:p-10">
      <header className="text-text-tertiary mb-6 flex flex-col items-center gap-1">
        <FlagIcon className="xl:hidden" width={32} height={32} />
        <FlagIcon className="hidden xl:block" width={40} height={40} />
        <h2 className="text-[20px] font-medium leading-[28px] xl:text-[32px] xl:leading-[44.8px]">
          {t('title')}
        </h2>
      </header>
      <main className="flex flex-col items-center">
        <div className="text-text-secondary mb-12 max-w-[456px] text-center font-medium leading-[22.4px] xl:text-lg xl:leading-[25.2px]">
          {t('content')}
        </div>
        <div className="w-full px-4 xl:px-8">
          <StyledButton
            variant="blue"
            size="large"
            className="leading[24px] w-full"
            onClick={closeDialog}
          >
            {t('button_label')}
          </StyledButton>
        </div>
      </main>
      <Dialog.CloseX
        className="hover:bg-text-hover right-0 top-0 z-50 h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white"
        onClick={closeDialog}
      />
    </section>
  )
}
