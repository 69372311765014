import { useMutation, UseMutationOptions } from '@tanstack/react-query'

export type CreateZendeskTicketParams = {
  subject: string
  comment: {
    body: string
    uploads?: string[]
  }
  requester: {
    name: string
    email: string
  }
  fields: unknown
  priority?: 'low' | 'normal' | 'high' | 'urgent'
  tags?: string[]
}

export type ZendeskRequestResponse = {
  request: { id: number }
}

const queryKey = 'createZendeskTicket'

async function uploadFile(file: File, auth: string): Promise<string> {
  const formData = new FormData()
  formData.append('file', file)

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_ZENDESK_API_URL_BASE}/uploads?filename=${file.name}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Basic ${auth}`,
      },
      body: formData,
    },
  )

  const result = await response.json()
  if (!response.ok) {
    throw new Error('File upload failed', result)
  }
  return result.upload.token
}

async function createZendeskTicket(
  params: CreateZendeskTicketParams,
  images: File[],
): Promise<ZendeskRequestResponse> {
  const auth = btoa(`${params.requester.email}/token:${process.env.NEXT_PUBLIC_ZENDESK_ID}`)

  // Upload files and get upload tokens
  const uploadTokens = images.length
    ? await Promise.all(images.map(file => uploadFile(file, auth)))
    : []

  const requestBody = {
    ...params,
    comment: {
      ...params.comment,
      uploads: uploadTokens,
    },
  }

  const response = await fetch(`${process.env.NEXT_PUBLIC_ZENDESK_API_URL_BASE}/requests`, {
    method: 'POST',
    body: JSON.stringify({ request: requestBody }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Basic ${auth}`,
    },
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return response.json()
}

export function useCreateZendeskTicketMutation<TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ZendeskRequestResponse,
    TError,
    { params: CreateZendeskTicketParams; images: File[] },
    TContext
  >,
) {
  return useMutation<
    ZendeskRequestResponse,
    TError,
    { params: CreateZendeskTicketParams; images: File[] },
    TContext
  >({
    mutationKey: [queryKey],
    mutationFn: variables => createZendeskTicket(variables.params, variables.images),
    ...options,
  })
}
