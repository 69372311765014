import * as React from 'react'
import {
  useAskCfUploadsCreateSignedUrlMutation,
  useAskCfGetUploadedQuery,
} from 'codegen/generated/ask-cf'

export interface UploadedFile extends File {
  uploadedUrl: string
}

export async function fetchUploadedImage(uploadId: string) {
  const response = await useAskCfGetUploadedQuery.fetcher({
    input: { uploadId },
  })()

  if (
    response.askCFGetUploaded &&
    response.askCFGetUploaded.errors &&
    response.askCFGetUploaded.errors.length
  ) {
    await new Promise(resolve => setTimeout(resolve, 2000))
    return fetchUploadedImage(uploadId)
  }
  return response.askCFGetUploaded
}

export function useUploadsImage() {
  const askCfUploadsCreateSignedUrlMutation = useAskCfUploadsCreateSignedUrlMutation()
  const [loading, setLoading] = React.useState(false)

  const uploadImage = async (file: File) => {
    try {
      const signedUrlResult = await askCfUploadsCreateSignedUrlMutation.mutateAsync({
        input: {
          uploadFile: file.name,
        },
      })

      const { id, signedUrl } = signedUrlResult.askCFUploadsCreateSignedUrl

      await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      })

      const uploadedImage = await fetchUploadedImage(id)
      return uploadedImage.imageUrl as string
    } catch (error) {
      throw new Error('Failed to upload image:', error as Error)
    }
  }

  const uploadImages = async (files: File[]): Promise<UploadedFile[]> => {
    setLoading(true)
    try {
      const uploadPromises = files.map(async file => {
        const castFile = file as UploadedFile
        castFile.uploadedUrl = await uploadImage(file)
        return castFile
      })
      return (await Promise.allSettled(uploadPromises))
        .map(result => (result.status === 'fulfilled' ? result.value : null))
        .filter(x => !!x) as UploadedFile[]
    } finally {
      setLoading(false)
    }
  }

  return { uploadImages, loading }
}
