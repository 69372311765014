'use client'

import * as React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { ChevronDownIcon } from 'primitives/icons'
import { tw } from 'utils/classnames'

// eslint-disable-next-line no-barrel-files/no-barrel-files
export { Root } from '@radix-ui/react-accordion'
// eslint-disable-next-line no-barrel-files/no-barrel-files
export type { AccordionSingleProps, AccordionMultipleProps } from '@radix-ui/react-accordion'

export function Header(props: React.ComponentProps<typeof Accordion.Header>) {
  return <Accordion.Header {...props} />
}

export function Item(props: React.ComponentProps<typeof Accordion.Item>) {
  return <Accordion.Item {...props} className={tw('group/item', props.className)} />
}

export const Trigger = React.forwardRef(function Trigger(
  {
    children,
    icon,
    ...props
  }: { icon?: JSX.Element } & React.ComponentProps<typeof Accordion.Trigger>,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <Accordion.Trigger
      {...props}
      ref={ref}
      className={tw(
        'group/trigger flex h-10 w-full items-center justify-between gap-2 rounded outline-none hover:text-gray-200 focus:text-gray-200',
        props.className,
      )}
    >
      {children}
      {icon ? (
        icon
      ) : (
        <ChevronDownIcon
          className="h-[16px] w-[16px] text-gray-400 transition-transform group-data-[state='open']/trigger:rotate-180"
          aria-hidden
        />
      )}
    </Accordion.Trigger>
  )
})

export const Content = React.forwardRef(function Content(
  props: React.ComponentProps<typeof Accordion.Content>,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <Accordion.Content
      {...props}
      ref={ref}
      className={tw(
        "data-[state='open']/item:animate-height-in",
        "data-[state='closed']/item:animate-height-out",
        'overflow-hidden',
        props.className,
      )}
    />
  )
})
