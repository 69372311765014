import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'
import {
  DndContext,
  useSensor,
  useSensors,
  closestCenter,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  UniqueIdentifier,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  SortableContext,
  rectSortingStrategy,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { SpinnerIcon } from 'primitives/icons'

import { AddImageAlternateIcon, CloudUploadIcon, WarningIcon } from '@ask-cf/assets/icons'
import { UploadedFile, useUploadsImage } from '@ask-cf/hooks/use-image-upload'
import { SortableItem } from './sortable-items'

export interface ImageInputProps {
  handleFile?: (files: UploadedFile[]) => void
  id: string
  name?: string
  currentFileCount: number
  images?: UploadedFile[]
  onFilesReordered: (images: UploadedFile[]) => void
  alt?: string
  handleDelete: (e: React.MouseEvent, index: number) => void
  imageUrls: Map<string, string>
  setLoading?: (loading: boolean) => void
}

const MAX_FILES = 10

export const ImageUploadInput = ({
  handleFile,
  id,
  name,
  currentFileCount,
  images = [],
  onFilesReordered,
  alt = '',
  handleDelete,
  imageUrls,
  setLoading,
}: ImageInputProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const t = useTranslations('ask_cf.components.image_upload')
  const [errorMessage, setErrorMessage] = React.useState<boolean | null>(false)
  const limit = MAX_FILES

  const { uploadImages, loading } = useUploadsImage()

  React.useEffect(() => {
    setLoading?.(loading)
  }, [loading, setLoading])

  const handleClick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation() // Prevent the event from bubbling up
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async event => {
    const filesUploaded = event?.target?.files
    if (filesUploaded) {
      const newFiles = Array.from(filesUploaded).filter(
        file =>
          !images.some(
            existingFile => existingFile.name === file.name && existingFile.size === file.size,
          ),
      )

      const uploadedFiles = await uploadImages(newFiles)
      const totalFiles = currentFileCount + uploadedFiles.length
      if (totalFiles <= MAX_FILES) {
        handleFile && handleFile(uploadedFiles)
        setErrorMessage(null)
      } else {
        const filesToAdd = uploadedFiles.slice(0, MAX_FILES - currentFileCount)
        handleFile && handleFile(filesToAdd)
        setErrorMessage(true)
      }
    }
    event.target.value = ''
  }

  const handleDrop: React.DragEventHandler<HTMLDivElement> = async e => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const newFiles = Array.from(e.dataTransfer.files).filter(
        file =>
          !images.some(
            existingFile => existingFile.name === file.name && existingFile.size === file.size,
          ),
      )
      const uploadedFiles = await uploadImages(newFiles)
      const totalFiles = currentFileCount + newFiles.length

      if (totalFiles <= MAX_FILES) {
        handleFile && handleFile(uploadedFiles)
        setErrorMessage(null)
      } else {
        const filesToAdd = uploadedFiles.slice(0, MAX_FILES - currentFileCount)
        handleFile && handleFile(filesToAdd)
        setErrorMessage(true)
      }
      e.dataTransfer.clearData()
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id && images) {
      const oldIndex = images.findIndex(img => img.name === active.id)
      const newIndex = images.findIndex(img => img.name === over.id)
      const newOrder = arrayMove(images, oldIndex, newIndex)
      onFilesReordered(newOrder)
    }
  }

  const preventDefaults: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  React.useEffect(() => {
    if (currentFileCount < MAX_FILES) {
      setErrorMessage(false)
    }
  }, [currentFileCount])

  return (
    <div className="relative">
      {loading ? (
        <div
          className="rounded-2 pointer-events-none absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-400 opacity-50"
          onClick={preventDefaults}
        >
          <SpinnerIcon className="mx-auto animate-spin" />
        </div>
      ) : null}
      <div
        className={tw(
          'flex flex-wrap justify-start gap-2 overflow-hidden p-2 xl:hidden xl:gap-4',
          loading && 'pointer-events-none',
        )}
        onClick={preventDefaults}
      >
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={images.map(img => img.name as UniqueIdentifier)}
            strategy={rectSortingStrategy}
          >
            {images?.length
              ? images.map((image, index) => (
                  <div key={index} className="relative">
                    <SortableItem
                      index={index}
                      imageUrl={imageUrls.get(image.name) || ''}
                      alt={alt}
                      handleDelete={handleDelete}
                      imageName={image.name}
                    />
                  </div>
                ))
              : ''}
          </SortableContext>
        </DndContext>
        {images.length < 10 ? (
          <button
            onClick={handleClick}
            className="rounded-2 flex h-[140px] w-[140px] cursor-pointer touch-auto flex-col items-center justify-center shadow-[0px_2px_8px_0px_#00000014,_0px_1px_1px_0px_#0000000A]"
            aria-label={t('upload_more_label')}
            disabled={loading}
          >
            <CloudUploadIcon className="mb-1 text-gray-600" />
            <div className="whitespace-nowrap text-xs leading-[18px]">{t('upload_more_label')}</div>
          </button>
        ) : null}
      </div>
      <div
        className={tw(
          'hidden w-full flex-wrap overflow-hidden xl:block',
          loading && 'pointer-events-none',
        )}
        onClick={preventDefaults}
      >
        <div
          onClick={images.length > 0 ? preventDefaults : handleClick}
          className={tw(
            'rounded-2 border-cf-black flex min-h-[105px] w-full flex-col items-start gap-4 border border-dashed border-opacity-10 bg-gray-100 px-4 py-3',
            images.length === 0 && 'cursor-pointer items-center justify-center',
          )}
          onDrop={handleDrop}
          onDragEnter={preventDefaults}
          onDragOver={preventDefaults}
          onDragLeave={preventDefaults}
        >
          <div className="flex cursor-pointer flex-col items-center gap-2" onClick={handleClick}>
            <AddImageAlternateIcon className="text-gray-500" width={20} height={20} />
            <div className="whitespace-nowrap text-xs leading-4 text-gray-700">
              {t('area_label')}
            </div>
          </div>
          <div className="relative flex w-full flex-wrap justify-start gap-2">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={images.map(img => img.name as UniqueIdentifier)}
                strategy={rectSortingStrategy}
              >
                {images?.length
                  ? images.map((image, index) => (
                      <div key={index} className="relative">
                        <SortableItem
                          index={index}
                          imageUrl={imageUrls.get(image.name) || ''}
                          alt={alt}
                          handleDelete={handleDelete}
                          imageName={image.name}
                        />
                      </div>
                    ))
                  : ''}
              </SortableContext>
            </DndContext>
            {images.length < 10 && images.length > 0 ? (
              <button
                onClick={handleClick}
                className="rounded-2 flex h-[140px] w-[140px] cursor-pointer touch-auto flex-col items-center justify-center shadow-[0px_2px_8px_0px_#00000014,_0px_1px_1px_0px_#0000000A]"
                aria-label={t('upload_more_label')}
              >
                <CloudUploadIcon className="mb-1 text-gray-600" />
                <div className="whitespace-nowrap text-xs leading-[18px]">
                  {t('upload_more_label')}
                </div>
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <input
        id={id}
        name={name}
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        className="absolute h-0 appearance-none overflow-hidden"
        accept="image/png, image/jpeg"
        multiple
        disabled={loading}
      />
      {errorMessage ? (
        <div className="mt-4 flex items-center justify-center">
          <div className="flex w-fit items-center justify-center bg-[#FEF7F5] p-2 align-middle">
            <WarningIcon className="mr-1 text-[#DA3B07]" />
            <div className="text-xs font-medium text-[#DA3B07]">
              {t('upload_limit_reached', { limit })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
