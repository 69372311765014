'use client'

import * as Dialog from 'primitives/dialog'
import * as React from 'react'

import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { NotifyLoginDialogBody } from './components/notify-login-dialog-body'
import { SimpleLoginModal } from '../login-button/components/simple-login-modal'

export function NotifyLoginDialog() {
  const { isOpen, closeLoginDialog, currentView } = useNotifyLoginDialog()

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={open => !open && closeLoginDialog()}
      defaultOpen={false}
    >
      <React.Suspense fallback={null}>
        <Dialog.Portal>
          <Dialog.Body className="font-poppins z-50 xl:overflow-y-auto xl:py-10" overlayZIndex={49}>
            {currentView === 'notify' ? <NotifyLoginDialogBody /> : <SimpleLoginModal />}
          </Dialog.Body>
        </Dialog.Portal>
      </React.Suspense>
    </Dialog.Root>
  )
}
