import * as React from 'react'
import ReactDOM from 'react-dom'
import { useQuery } from '@tanstack/react-query'
import { lazyComponent } from 'utils/lazy-component'

import { LFS_URL } from '@ask-cf/config'

const url = `${LFS_URL}/ask-cf/lottie/AskCF_Animation01_NoBG.json`
const LazyLottieComponent = lazyComponent(() => import('lottie-react').then(m => m.default))

export function SearchBannerAnimation({ fallback }: { fallback: React.ReactNode }) {
  // @ts-expect-error
  ReactDOM.preload(url, { as: 'fetch' })
  React.useEffect(() => {
    LazyLottieComponent.preload()
  }, [])

  // eslint-disable-next-line local/codegen-query-patterns
  const query = useQuery({
    queryKey: ['lottie', 'search-banner-animation'],
    queryFn: async () => {
      return fetch(url).then(res => res.json())
    },
  })

  if (!query.data) {
    return fallback
  }
  return (
    <React.Suspense fallback={fallback}>
      <LazyLottieComponent.component animationData={query.data} loop={true} />
    </React.Suspense>
  )
}
