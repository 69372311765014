export function BubblePointer({ className = '' }: { className?: string }) {
  return (
    <>
      <svg
        width="25"
        height="27"
        viewBox="0 0 25 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`block xl:hidden ${className}`}
      >
        <path
          d="M19.4383 2.15713L0 0L6.22228 26.5427L22.3839 12.1126C26.257 8.65451 24.5008 2.71893 19.4383 2.15713Z"
          fill="white"
        />
      </svg>

      <svg
        width="45"
        height="36"
        viewBox="0 0 45 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`hidden xl:block ${className}`}
      >
        <path
          d="M7.88891 31.1785L0.539062 0.506836L44.5743 6.67005L18.0246 33.9639C14.7285 37.3525 8.99051 35.7756 7.88891 31.1785Z"
          fill="white"
        />
      </svg>
    </>
  )
}
