'use client'

import * as React from 'react'
import { usePathname } from 'next/navigation'
import { useDomEvent } from 'hooks/use-dom-event'
import { locales } from 'utils/locales'

import { ROUTES } from '@ask-cf/constants/app-routes'

const routesWithMinimizedBanner = [
  ROUTES.ABOUT('|locale|'),
  ROUTES.ALL_TOPICS('', '|locale|'),
  ROUTES.YOUR_QUESTIONS('|locale|'),
  ROUTES.YOUR_ANSWERS('|locale|'),
  ROUTES.SEARCH_RESULTS('', '', '|locale|'),
  ROUTES.BADGES('|locale|'),
  ROUTES.QUESTION_DETAILS('*', '*', '|locale|'),
  ROUTES.TOPIC_DETAILS('*', '|locale|'),
  ROUTES.NOTIFICATIONS('|locale|'),
]

export interface BannerContextModel {
  bannerActive: boolean
  setIsError: (value: boolean) => void
}

export const BannerContext = React.createContext<BannerContextModel>({
  bannerActive: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsError: () => {},
})

export const BannerProvider = ({ children }: React.PropsWithChildren) => {
  const [scrollActive, setScrollActive] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const pathname = usePathname()

  React.useEffect(() => {
    // Only update the scroll state after mounting on the client
    const updateScrollActive = () => setScrollActive(window.scrollY > 0)
    window.addEventListener('scroll', updateScrollActive)
    updateScrollActive()

    return () => window.removeEventListener('scroll', updateScrollActive)
  }, [])

  useDomEvent(
    useDomEvent.window,
    'scroll',
    React.useCallback(() => setScrollActive(window.scrollY > 0), []),
  )

  const escapeRegExp = (input: string) => input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const routePattern = React.useCallback((route: string) => {
    const localePattern = `(?:/(?:${locales.join('|')}))?`
    const escapedRoute = escapeRegExp(route)
      .replace('/\\|locale\\|', localePattern)
      .replace(/\\\*/g, '[^/]+')
    return new RegExp(`^${escapedRoute}(/?|\\?.*)$`)
  }, [])

  const isRouteWithMinimizedBanner = React.useMemo(
    () => routesWithMinimizedBanner.some(route => routePattern(route).test(pathname)), // matches /path, /path/ and /path?test=abc
    [pathname, routePattern],
  )

  const value = scrollActive || isRouteWithMinimizedBanner || isError

  return (
    <BannerContext.Provider value={{ bannerActive: value, setIsError }}>
      {children}
    </BannerContext.Provider>
  )
}
