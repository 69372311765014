'use client'

import * as React from 'react'

import { UploadedFile } from '@ask-cf/hooks/use-image-upload'

export interface QuestionDialogContextModel {
  isOpen: boolean
  question: string
  questionContext: string
  images: UploadedFile[]
  openDialog: (question?: string) => void
  closeDialog: () => void
  setQuestion: React.Dispatch<React.SetStateAction<string>>
  setQuestionContext: React.Dispatch<React.SetStateAction<string>>
  setImages: React.Dispatch<React.SetStateAction<UploadedFile[]>>
  closeClearModalData: () => void
}

const QuestionDialogContext = React.createContext<QuestionDialogContextModel>(null!)

export const QuestionDialogProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [question, setQuestion] = React.useState<string>('')
  const [questionContext, setQuestionContext] = React.useState<string>('')
  const [images, setImages] = React.useState<UploadedFile[]>([])

  const openDialog = (initialQuestion?: string) => {
    setIsOpen(true)
    setQuestion(initialQuestion || '')
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const closeClearModalData = () => {
    setQuestion('')
    setQuestionContext('')
    setImages([])
    setIsOpen(false)
  }

  return (
    <QuestionDialogContext.Provider
      value={{
        isOpen,
        question,
        questionContext,
        images,
        openDialog,
        closeDialog,
        setQuestion,
        setQuestionContext,
        setImages,
        closeClearModalData,
      }}
    >
      {children}
    </QuestionDialogContext.Provider>
  )
}

export const useQuestionDialog = () => {
  const context = React.useContext(QuestionDialogContext)

  if (context === undefined) {
    throw new Error('useQuestionDialog must be used within a QuestionDialogProvider')
  }

  return context
}
